
// --- DARKMODE ---
html.darkmode {
	.favorite-button-placeholder__recipe {
		button {
			background-color: $darkmode-background;
			color: $darkmode-text;
			border: 1px solid transparent;
			svg {
				color: $darkmode-text;
			}
			&:focus,
			&:hover { 
				box-shadow: none;
				border-color: $nrk-color-core-blue-500;  
			}
		}
	}
	.recipe {
		.widget-title {
			border-color: $darkmode-border-light;	
		}	
	}
  .recipe-card {
		border: none !important;
		background-color: $darkmode-background; 
  }
  .recipe-card__body, .recipe-card__name  {
    color: $darkmode-text;
  }

  .recipe-list {
    color: inherit;
  }

  .recipe-button > span {
    color: $darkmode-link;
  }

  .recipe-meta-title {
    color: $darkmode-text;
  }

  .recipe .article-header:before,
  .recipe .article-content:before,
  .recipe .recipe-footer:before {
    border-color: $darkmode-border-light;
  }

  .recipe {
    .caption-cite {
      color: $darkmode-text-byline;
    }
  }

	.recipe-hr {
		border-color: $darkmode-border-light;
	}
 
  .recipe-shareurl input {
    border-color: $darkmode-border-light;
    color: $darkmode-text;
    background-color: $darkmode-background;
  }

	.recipe-button--print:before, .recipe-icon-time:before, .recipe-icon-portions:before {
		background: $darkmode-text;
	}

	.recipe-icon-difficulty:before {
		background: linear-gradient(90deg, $darkmode-text 0%, $darkmode-text 33%, $nrk-color-core-blue-700 33%, $nrk-color-core-blue-700 100%);
	}

	.recipe-icon-difficulty-medium:before {
		background: linear-gradient(90deg, $darkmode-text 0%, $darkmode-text 66%, $nrk-color-core-blue-700 66%, $nrk-color-core-blue-700 100%);
	}

	.recipe-icon-difficulty-hard:before {
		background: $darkmode-text;
	}
}
// --- DARKMODE END ---

.recipe .title-large {
	margin-top: 0;
	font-weight: 400;
  letter-spacing: 0;
  font-variation-settings: "wdth" 100;
}

.recipe .text-body > p {
	margin: .2em 0 1em;
}

.recipe .text-body > h2 {
	font-weight: 640;
}
.article-preamble {
	font-size: 1.2em;
	margin: 0 0 0.6em;
}

.recipe-sidebar {
	margin-bottom: 20px;
}
.recipe-aside {
	margin-bottom: 30px;
}

.recipe-list {
	fontSize(16px);
	line-height: 1.42em;
	margin: 0 0 15px 0;
	padding: 0 0 0 15px;
}

.recipe-list-meta {
	padding-left: 0;
	list-style-type: none;
	margin-top: 20px;
}

.recipe-list li {
	clear: both;
	margin-bottom: 6px;
}

.recipe-list li {
  font-weight: 400;
}

.recipe-list li strong, .recipe-meta-title.recipe-meta-title {
  font-weight: 640;
}

/* target only the suitable for recipe list */
.recipe-list.recipe-list--suitable-for {
	display: inline;
	list-style: none;
	padding-left: 0;
}

.recipe-list.recipe-list--suitable-for li {
	display: inline-block;
}

.recipe-list.recipe-list--suitable-for li:first-child::first-letter {
	text-transform: uppercase;
}

.recipe-list.recipe-list--suitable-for li:after {
	content: ', ';
	margin-right: 0.25em;
}

.recipe-list.recipe-list--suitable-for li:last-child:after {
	content: '';
}

.recipe-hr {
	height: 0;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 20px 0;
}

.recipe-meta-title {
	fontSize(16px);
	font-weight: 600;
	margin: 0 0 7px 0;
	color: #26292A;
}

.recipe-meta-title.recipe-meta-title-small {
	font-size: 14px;
}

.recipe-icon {
	list-style-type: none;
	font-size: 13px;
	line-height: 21px;

	margin-bottom: 15px !important;
	clear: none !important;
}

.recipe-icon:before {
	display: block;
	float: left;
	content: '';
	margin-right: 7px;
	background: transparent no-repeat 0 0;
}

.recipe-icon-time,
.recipe-icon-difficulty {
	float: left;
}

.recipe-icon-portions:before {
	background: $nrk-color-gray-800;
	mask: url(https://static.nrk.no/core-icons/latest/icon/nrk-mat.svg) no-repeat;
	-webkit-mask: url(https://static.nrk.no/core-icons/latest/icon/nrk-mat.svg) no-repeat;
}

.recipe-icon-time:before {
	width: 17px;
	height: 20px;
	background: $nrk-color-gray-800;
	mask: url(https://static.nrk.no/core-icons/latest/icon/nrk-duration.svg) no-repeat;
	-webkit-mask: url(https://static.nrk.no/core-icons/latest/icon/nrk-duration.svg) no-repeat;
}

.recipe-icon-difficulty:before {
	width: 60px;
	height: 20px;
	background: $nrk-color-gray-800;
	background: linear-gradient(90deg, $nrk-color-gray-800 0%, $nrk-color-gray-800 33%, $nrk-color-gray-200 33%, $nrk-color-gray-200 100%);
	mask: url(img/hat.svg) no-repeat;
	-webkit-mask: url(img/hat.svg) no-repeat;
	mask-size: contain;
	-webkit-mask-size: contain;
	mask-repeat: space;
	-webkit-repeat: space;
}

.recipe-icon-difficulty-medium:before {
	background: linear-gradient(90deg, $nrk-color-gray-800 0%, $nrk-color-gray-800 66%, $nrk-color-gray-200 66%, $nrk-color-gray-200 100%);
}

.recipe-icon-difficulty-hard:before {
	background: $nrk-color-gray-800;
}

.recipe-icon-time + .recipe-icon-difficulty {
	margin-left: 20px;
}

.recipe-icon-portions:before {
	height: 20px;
	width: 20px;
	background-position: -17px 0;
}

.recipe-icon-portions {
	clear: both !important;
}

.recipe-universes {
	clear: both;
}

.recipe-footer {
	margin-bottom: 20px;
}

.recipe-button {
	display: block;
	border: none;
	background: transparent;
	padding: 0;
	line-height: 20px;
	font-size: 13px;
	color: #26292A;
}

.recipe-button > span {
  color: $nrk-color-core-blue-600;
  border-bottom: 1px solid $nrk-color-gray-300;

	&:hover {
		border-color: $nrk-color-core-blue-600;
	}
}

.recipe-button--print{
	display: none;
}

.recipe-button__icon,
.recipe-button--print:before{
	float: left;
	height: 20px;
	width: 20px;
	margin-right: 10px;
}

.recipe-button--print:before {
	content: '';
	width: 24px;
	background: $nrk-color-gray-800;
	mask: url(https://static.nrk.no/core-icons/latest/icon/nrk-hardware-printer.svg) no-repeat;
	-webkit-mask: url(https://static.nrk.no/core-icons/latest/icon/nrk-hardware-printer.svg) no-repeat;
	mask-size: contain;
	-webkit-mask-size: contain;
}

.recipe .article-content .text-body {
	margin-bottom: 20px;
}

.recipe .article-content .caption-cite {
	display: block;
	margin-top: -2px; /* XXX */
	letter-spacing: 0.03em;
}

.recipe-shareurl {
	padding-top: 10px;
	font-weight: 700;
	fontSize(16px);
}

.recipe-shareurl input {
	width: 220px;
	margin: 2px 0;
	border: 1px solid #e0e0e0;
	padding: 4px 7px 3px;
	font-family: inherit;
	font-size: inherit;
}

@media screen and (min-width: 720px) {
	.s18 > .recipe .article-header {
		padding-left: 40px;
	}

	.s18 > .recipe .article-content,
	.s18 > .recipe .recipe-footer {
		padding-left: 30px;
	}

	.s18 > .recipe .article-header,
	.s18 > .recipe .article-content,
	.s18 > .recipe .recipe-footer {
		position: relative;
	}

	.s18 > .recipe .recipe-list {
		// margin-bottom: 0px;
	}

	.s18 > .recipe .article-header:before,
	.s18 > .recipe .article-content:before,
	.s18 > .recipe .recipe-footer:before {
		display: block;
		content: '';
		position: absolute;
		top: 10px;
		bottom: 0;
		left: 0;
		border-left: 1px solid #cccccc;
	}

	.s18 > .recipe .article-content:before,
	.s18 > .recipe .recipe-footer:before {
		left: -10px;
		top: -30px;
	}

	.s18 > .recipe .recipe-footer:before {
		bottom: 8px;
	}

	.s18 > .recipe {
		margin-bottom: 15px !important;
	}

	.s18 > .recipe .recipe-header {
		margin-bottom: 20px;
	}

	/* XXX */
	.s18 > .recipe .recipe-media + .col > .article-header {
		margin-top: 10px;
	}

	.s18 > .recipe .recipe-button--print {
		display: block;
	}

	.recipe-share {
		float: right;
	}

	.recipe-footer {
		margin-bottom: 25px;
	}
}

@media print {
	.recipe-icon:before {
		width: auto;
		background-image: none;
	}

	// Removes recipe widgets inside the tips section.
	.recipe-article__tips {
		.recipe.widget.reference {
			display: none;
		}
	}

	.recipe-no-print {
		display: none;
	}

	.searchwidget {
		display: none;
	}

	// Double selector needed to get high enough specificity to overwrite rules from bottommenu.styl
	.nrk-bottommenu-footer.nrk-bottommenu-footer {
		background: #fff
	}

}

/* Recipe card (reference)
--------------------------------------------------------*/
.recipe-card {
	display: block;
	overflow: hidden;
	transition: .2s;
	border: 1px solid #e0e0e0!important;
}
.recipe-card:hover .recipe-card__name {
	text-decoration: underline;
}
.recipe-card__body {
	padding: 16px 20px 20px;
	line-height: 1;
	color: #6c6c6c;
}
.recipe-card__type {
	font-size: 11px;
	letter-spacing: 1px;
	text-transform: uppercase;
}
.recipe-card__name {
	margin: 5px 0 0;
	font-size: 22px;
	color: #26292a;
}
.recipe-card__meta {
	list-style: none;
	margin: 0;
	padding: 0;
	font-size: 12px;
	line-height: 16px;
}
.recipe-card__meta li {
	display: inline-block;
	vertical-align: top;
	margin: 20px 13px 0 0!important;
}
.recipe-card__meta .recipe-icon{
	float: none;
	font-size: inherit;
	line-height: inherit;
}
.recipe-card__meta .recipe-icon::before{
	margin: 0;
	opacity: .6;
	transform: scale(.75);
	transform-origin: 0 0;
}
.recipe-card__meta .recipe-icon-difficulty::before {
	margin-right: -6px;
}
.recipe-card__meta .recipe-icon-tag:before {
	width: 22px;
	height: 20px;
	margin-right: -2px;
	background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2070%2070%22%3E%3Cpath%20fill%3D%22%233d3d3d%22%20d%3D%22M18%2012.8a5%205%200%200%200-5.2-5%205%205%200%200%200-5%205%205%205%200%200%200%205%205.2%205%205%200%200%200%205.2-5.2zM61%2036a5%205%200%200%201-1.6%203.6L39.6%2059.3a5%205%200%200%201-3.7%201.5%205%205%200%200%201-3.7-1.5L3.6%2030.6a12%2012%200%200%201-2.6-4%2012.4%2012.4%200%200%201-1-4.8V5a5%205%200%200%201%201.5-3.5A5%205%200%200%201%205%200H22a12.4%2012.4%200%200%201%204.7%201%2012.5%2012.5%200%200%201%204%202.7l29%2028.6a5%205%200%200%201%201.4%203.7z%22%2F%3E%3C%2Fsvg%3E") 50%/contain no-repeat;
}
.recipe-card__meta .recipe-icon-tag + .recipe-icon-tag::before{
	border-left: 1px solid;
	margin: 1px -8px;
	width: 0;
	height: 18px;
	background: none;
}

.recipe .recipe-header .fb-post {
	text-align: center
}

@media(min-width: 720px){
	.recipe-card:active { transform: scale(.99) }
	.recipe-card:hover { border-color: #c8c8c8!important }
}

.recipe .recipe-universes .universe .pagination {
	display: none;
}

.flow-content > .col > .widget.recipe.reference {
	padding-top: 0;
	padding-bottom: 0;
}

.article-content .widget.recipe.reference {
	margin-top: 1em;
	margin-bottom: 2em;
}

