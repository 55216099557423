.summer-header {
	display:none;
}

@media screen and (min-width: 720px) {
	.summer-header {
		display:block;
		float:right;
		margin: -95px 15px 0 15px !important;
		position:relative;
		z-index:200;
	}

	.summer-header a {
		background: transparent url(img/sommerapent_2016.png) no-repeat;
		width:70px;
		height:47px;
		display:block;
		float:left;
		text-indent: 100%;
		white-space: nowrap;
		overflow: hidden;
		padding:0;
		margin:0 7px;
	}
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.summer-header a {
		background-image: url(img/sommerapent_2016_2x.png);
		background-size: 70px 47px;
	}
}
