// --- Darkmode overrides ---
html.darkmode {
  // unset darkmode-colors to use skin-colors
  .autonomous.forward-navigation {
    .plug-metadata.plug-byline {
      color: inherit;
    }

    .title, .plug-preamble {
      color: inherit;
    }

    .review-summary {
      color: inherit;
      background-color: inherit;

      .review-tag {
        background-color: inherit;
        color: inherit;
      }
    }

    .widget.reference.plug.plug-feature .autonomous {
      .plug-teaser {
        background-color: inherit;
      }
    }
  }


}

// --- Darkmode overrides end ---

// --- Font-weights and variations ---
.article.widget.reference.plug.plug-feature, .sanitycontent.widget.reference.plug.plug-feature, .link.widget.reference.plug.plug-feature, .plug-feature {
  .autonomous.forward-navigation {
  .title {
    font-weight: 900;
    }
  }
}



.autonomous.forward-navigation {
  [class*='skin-text'] > *:not([class*='skin-text']) { // children of elements with skin-text-classnames should inherit that color, unless they themselves have a skin-text-classname
    color: inherit;
  }

  .title {
    font-weight: 800;
    font-variation-settings: 'wdth' 90;
  }

  .kicker {
    font-weight: 640;
  }

  .plug-preamble, .plug-metadata.plug-byline {
    font-weight: 400;
  }

  // --- Font-weights and variations end ---

  .review-summary, .review-summary .review-tag {
    color: inherit;
    background-color: inherit;
  }

  .plug-metadata.plug-byline {
    color: inherit;
  }
}

a.autonomous.lp_plug[href*="/ytring"] .plug-byline { display: block; }
a.autonomous.lp_plug .plug-byline { display: none; }

a.autonomous {
  .review-tag {
    .review-type {
      text-decoration: none;
    }
  }

  &.link__container--with-thumbnail {
    .thumbnail-container .plug-image, .thumbnail-container .plug-image .responsive-img {
      box-sizing: border-box;
      height: calc(100% - 0.5px); // due to images sometimes showing under the svg-overlay (prabably due to rounding decimal-pixels) we subtract 0.5px from the height to make sure it rounds down, not up
    }
  }
}
