@require "../../common/css/mixins";

// --- DARKMODE ---
html.darkmode {
  .author {
    a {
      color: $darkmode-link;
    }
    color: $darkmode-text;
  }
}
// --- DARKMODE END ---

.author {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.6;
  color: #6c6c6c;
}
.author a {
  border: 0 !important; /* Removes border used to underline links. Affects bylines in XL-articles */
  text-decoration: underline;
  text-underline-offset: 2px;
  color: $nrk-color-core-blue-600;
  margin-top: 2px;
  line-height: 1.4;
}
.author__name {
  font-size: 15px;
  font-weight: bold;
  display: flex;
}

/* If only one line, show more should be on same line */
.authors--expandable .author:first-of-type { display: inline-block }

.authors--expandable .author__image, .author__image,
.authors--expandable .author__twitter, .author__twitter,
.authors__expand:not(:target) ~ .author,
.authors__expand:target {
  display: none
}

.authors__expand {
  border: 0!important;
  color: $nrk-color-core-blue-600;
  font-weight: 700;
  font-size: 0.9375rem;
  float: right;
  margin-top: 0.3rem;
}

@media(min-width: 720px){
  .author {
    white-space: nowrap; /* Make sure image aligns next to body */
    margin-bottom: 20px;
    line-height: 1.3;
  }
  .author__image,
  .author__body {
    white-space: normal; /* Re-allow word-wrapping */
    display: inline-block;
    vertical-align: middle;
  }
  .author__image {
    overflow: hidden;
    margin-right: 9px;
    border-radius: 100%;
    background: #eee url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2072%2064%22%3E%3Cpath%20opacity%3D%22.3%22%20d%3D%22M56.6%2044.2c-4.8-.8-7.3-5-10.6-8V33c4.6-8%207-16.4%203.6-25.5C45.8.3%2043%202.7%2039.6%201s-5-1-7.4.5C22.4%206%2019.2%2016.7%2024.7%2027S28.2%2039.4%2019%2043a7.4%207.4%200%200%201-2.4.8c-11%200-15.3%207-16.2%2017.3%200%201-.3%202-.4%203h72c-1-10-4.3-18-15.4-19.8z%22%2F%3E%3C%2Fsvg%3E') 0 100%/100% no-repeat;
    filter: grayscale(100%);
    width: 50px;
    height: 50px;
  }
  .author__name { font-size: 14px }

  .authors--expandable .author { margin-bottom: 13px }
  .authors__expand {
    display: block;
    font-size: 0.875rem;
    margin-top: 0;
    float: none;
  }
}
