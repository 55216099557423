@require '../../common/css/mixins';
// --- DARKMODE ---
html.darkmode {
  .stream.lean {
    .stream-header {
      background-color: $nrk-color-core-blue-800
      .stream-title,
      time {
        color: $nrk-color-core-blue-50;
      }
    }

    .teaser.reference {
			border: none;
      background-color: $darkmode-background;
      border-top: 1px solid $darkmode-border-light;
      border-color: $darkmode-border-light;
      &.emphasis-high {
      	border-color: $darkmode-border-light;
        background-color: $nrk-color-warm-pink-950;
        &:hover {
          background-color: $nrk-color-warm-pink-900;
        }
      }
      &:hover {
        background-color: $nrk-color-core-blue-900;
      }
      a {
        color: $darkmode-text;
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  .stream-content {
    border-color: $darkmode-border-light;
  }

  .stream-lean-footer {
    border-color: $darkmode-border;
    background-color: $darkmode-body-background;
    a {
      color: $darkmode-text;
      &:hover {
        background: $darkmode-background;
      }
    }
  }
}
// --- DARKMODE END ---

.stream-content {
	padding: 0;
	margin: 15px 0 0 0;
	list-style-type: none;
	position: relative;
	border-left: 1px solid #e0e0e1;
}

.stream-item:last-child .bulletin:not(.emphasis-high) {
	padding-bottom:0;
	margin-bottom:24px;
}

.stream-item .bulletin.emphasis-high {
	margin-bottom:24px;
}

/* insertion animation (see also Serum.RealtimeStream js) */
.stream-item.new .bulletin.rich > div {
	max-height: 0px;
	opacity: 0;
	overflow: hidden;
}

.stream-item.new .bulletin.rich > div {
	max-height: 0px;
	opacity: 0;
	overflow: hidden;
}

/* hide long publication time inside stream*/
.stream-item .bulletin-publish-date,
.stream-item .bulletin-publish-clock,
.stream-item .bulletin-publish-year {
	display: none;
}

.no-js .stream-item .bulletin-publish-date,
.no-js .stream-item .bulletin-publish-clock,
.no-js .stream-item .bulletin-publish-year {
	display: inline;
}

.no-js .stream-footer,
.stream-footer.hidden {
	display:none;
}

/* stream lean */
.stream.lean .stream-header {
	background: $nrk-color-core-blue-950;
	padding:10px 15px;
}

.stream.lean .stream-title {
	font-nrk-sans(16px, 20px);
	color: $nrk-color-core-blue-400;
	margin: 0;
}

.stream.lean .stream-title time {
	color:#fff;
	display:inline-block;
	position:relative;
	min-width:72px;
	margin-right:10px;
}

.stream.lean .stream-title time:after {
	position:absolute;
	display:block;
	content:" ";
	width:1px;
	height:15px;
	top:3px;
	right:0;
	background:	#686A6C;
}

.stream.lean .teaser.reference {
	display: block;
	border-bottom: 1px solid #F6F6F6;
	box-sizing: border-box;
  	max-height: 40px;
}

.stream.lean .teaser.reference > a {
	fontSize(15px, 24px);
	display: block;
	padding:8px 15px;
	color: $nrk-color-core-blue-950;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.stream.lean .teaser.reference > a:hover {
	background-color: #F6F6F6;
}

.stream.lean .teaser.reference > a > time {
	font-nrk-sans(15px, 17px);
	margin-right: 15px;
}

.stream.lean .teaser.reference.emphasis-high {
	border-bottom:1px solid white;
	background-color: $nrk-color-red-400;
}

.stream.lean .teaser.reference.emphasis-high > a,
.stream.lean .teaser.reference.emphasis-high > a > time {
	color: $nrk-color-core-blue-950 !important;
}

.stream.lean .teaser.reference.emphasis-high > a:hover {
	background-color: $nrk-color-red-500;
}

.stream.lean .stream-item {
	margin-bottom: 0px;
}

.stream.lean .stream-item > .teaser {
	//border-bottom: 1px solid #dfdfdf;
}

.stream.lean .stream-item:last-child > .teaser {
	border-bottom: 0;
}

.stream.lean .stream-lean-content {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.stream-lean-footer {
	position:relative;
	border:1px solid #E0E0E0;
	margin: -10px 10px 30px 10px;
}

.stream-lean-footer .arrow-right {
	height:14px;
	position:absolute;
	right:10px;
	top:50%;
	transform:translateY(-50%);
	pointer-events:none;
	color:#6C6C6C;
}

.stream-lean-exit {
	fontSize(15px, 18px);
	font-weight: 600;
	display: block;
	padding: 10px 15px;
	color: $nrk-color-core-blue-950;
}

.stream-lean-exit:hover {
	background-color: #F6F6F6;
}

.stream-lean-exit span {
	color: $nrk-color-red-600;
}

.stream-item.new .teaser.reference {
	max-height: 0px;
	overflow: hidden;
}

@media screen and (min-width: 720px) {
	.stream-content {
		margin-top:0;
	}

	.stream-item:last-child .bulletin,
	.stream-item .bulletin.emphasis-high {
		margin-bottom:30px;
	}

	/* display stream content inside 18 columns centered */
	.s18 > .stream > .stream-content,
	.s18 > .stream > .stream-footer {
		max-width: 66.666666666%;
		margin: 0 auto;
	}
	.stream-lean-footer {
		margin: -15px 15px 25px 15px;
	}
}
