@require '../../common/css/mixins';

// --- DARKMODE ---
html.darkmode {
 .article-feature {
    .article-feature__intro {
      background-color: $darkmode-background;
      color: $darkmode-text;
    }
    .article-feature__dates {
      color: $darkmode-text-byline
    }

    .widget:not(.fact) {
      .title {
        color: $darkmode-text;
      }
    }

		@media(max-width: 899px) {
			.authors {
				border-color: $darkmode-border-light;
			}
		}
  }

  .article-feature__footer {
    border-color: $darkmode-border;
  }

  .article-feature__cover.fc.fc.is-fullscreen {
    background: $darkmode-body-background;
    figcaption {
      background: $darkmode-body-background;
    }
  }

  .article-feature > .fr.image-reference small,
  .article-feature__body > .fr.image-reference small {
    opacity: 1
  }

  .article-feature > .fr.image-reference figcaption,
  .article-feature__body > .fr.image-reference figcaption {
    background: $darkmode-background;;
    color: $darkmode-text;
  }
}
// --- DARKMODE END ---


/* Article Feature
------------------------------------------------------------------------------*/
.article-feature {
	box-sizing: content-box;
	margin: auto;
	max-width: 590px;
	padding: 0 18px 50px;
}
.article-feature,
.article-feature__body {
	font-size: calc(18px + .1vw);
}

.article-feature .article-feature__expand-image-button {
		display: none;
		position: absolute;
		z-index: 9;
		background: rgba(0,0,0,0.35);
		color: #fff;
		font-size: 12px;
		font-weight: 600;
		text-shadow: 0 0 2px #000000;
		line-height: 28px;
		height: 30px;
		border-color: transparent;
		&::after {
			right: 0;
		}

}
.article-feature .article-feature__intro {
	box-sizing: border-box;
	text-align: center;
	position: relative;
	z-index: 99;					/* Position over album/kart/plugin etc */
	margin: -10% 0 0 calc(50% - 46vw);
	width: 92vw;
	padding: 5% 5% 0;
	font-weight: 640;
	font-size: 1.1em;
	line-height: calc(1.25em + .2vw);
	background: #fff;
}
.article-feature__intro::before,
.article-feature__intro::after {
	content: '';
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAABACAYAAADMM42JAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAATdJREFUeNqsVEFuxCAMtA3P24/02Jf02I/0ew3drAA5ZgymGyQLEo2H8dgJPz4+KbJ+vr84rwD6OUdAz3U+j8wGyDryAij1LBewA2R1FqRZA9ueOlixWuAlMmAle31lTgIq14AOPIMV20Vf7UEDZ8tMTmHJk0FecSe7ONaRLe48C5oB1BCkmSfNSeJMpx4gV0YBCf0WCXwk/RbE3OKo8dt2UVcXUKTM3KDKYtlf78SRgOJAYDLMBTFbGYfHXCZ7D8RcAPNhNVsmskleu7Vt0wIRMyHrvKQlc1lp9joJ3Zg6M3NjsG81/MsOul2UCOOKGd7gWQf3t5nDPk8nMCqDVj4PSbd08F/gsIxhAqPMr6QU+DeT93+eer0lI2Id7VpHt/o8yNmRQVHraLtADgJ5W/PO4i3mPwEGAD6ou6/YrlcFAAAAAElFTkSuQmCC') 0/100% 100% no-repeat;
	position: absolute;
	left: -11px;
  width: 11px;
  height: 70px;
	top: 0;
}
.article-feature__intro::after { transform: scaleX(-1); left: 100% }
.article-feature__intro h1 {
	margin: 0 0 -.5rem;
	font-weight: 900;
	font-size: calc(20px + 3.5vw);
	line-height: 1.25; // Increase line height to accommodate NRK Sans Variable
}
.article-feature__cover.has-media ~ .article-feature__intro { margin-top: -7px }
.article-feature__footer {
  border-top: 1px solid #c8c8c8;
  padding-top: 15px;
}

/* Typography
------------------------------------------------------------------------------*/
.article-feature .author,
.article-feature .widget figcaption {
  font-weight: normal;
}

.article-feature strong,
.article-feature li h2,
.article-feature .author__name,
.article-feature .authors--expandable .author__name,
.article-feature .authors--expandable .authors__expand {
  font-weight: 640;
}

.article-feature .quote p,
.article-feature > .quote-reference p,
.article-feature__body > .quote-reference p {
  font-weight: 600;
}


/* Authors and time
------------------------------------------------------------------------------*/
.article-feature .authors { margin: 1.5% 0 6.5%; line-height: 1 }
.article-feature .authors:not(.authors--expandable) .author {										/* Authors in row */
	display: inline-block;
	text-align: left;
	margin-right: 15px;
}
.article-feature .author.author { margin-bottom: 0 }
.authors--expandable .article-feature__dates { margin-bottom: 2% }              /* Less space when no images */
.article-feature .authors--expandable .author__role,														/* Show role inline */
.article-feature .authors__expand:not(:target) { display: inline }							/* Show more-button */
.article-feature .authors__expand:not(:target) ~ .author,												/* Hide authors after (+) */
.article-feature__dates .icon { display: none }
.article-feature__dates {
  display: block;
  text-transform: uppercase;
  margin: 4px 0 8px;
  font-size: 12px;
  letter-spacing: .05em;
  color: #6c6c6c;
}


/* Content (Double selectors overwrites .text-body)
------------------------------------------------------------------------------*/
.article-feature > *,
.article-feature__body > * { box-sizing: border-box; float: left; width: 100% }				/* Normalize floating */
.article-feature > .fln.fln,																										/* Inline */
.article-feature > .fl.fl,  																										/* Inline */
.article-feature > .fr.fr,																											/* Sidebar */
.article-feature > .fc.fc,
.article-feature__body > .fln.fln,																										/* Inline */
.article-feature__body > .fl.fl,  																										/* Inline */
.article-feature__body > .fr.fr,																											/* Sidebar */
.article-feature__body > .fc.fc {																											/* Full width */
	box-sizing: border-box;
	float: left;
	clear: left;
	width: 100vw;
	margin-left: -18px;																														/* Play nice with iOS6 */
	margin: 3% 0 1.5em calc(50% - 50vw);
}

.article-feature .widget figcaption small { margin-top: 5px }
.article-feature .widget figcaption small:first-child { margin-top: 0}
.article-feature .widget p { font-size: calc(13px + .05vw); line-height: 1.35 }
.article-feature .widget .title,
.article-feature .quote p,
.article-feature > h2,
.article-feature__body > h2 {
	font-size: calc(1.2em + .1vw);
	font-family: inherit;
	letter-spacing: normal;
	line-height: 1.3;
	padding: 0;
}
.article-feature > h2,
.article-feature__body > h2 { margin: 6% 0 2% }
.article-feature > p:empty,
.article-feature__body > p:empty { display: none }                                    /* Hide empty paragaphs */
.article-feature > .fact-reference .fact,
.article-feature__body > .fact-reference .fact { font-size: .9em }
.article-feature > .album-reference .album.album,
.article-feature__body > .album-reference .album.album { margin: 0 }									/* Reset album.styl margin */
.article-feature > .fr.image-reference small,
.article-feature__body > .fr.image-reference small { opacity: .77 }										/* Fade credits in sidebar */
.article-feature > .fr.image-reference figcaption,
.article-feature__body > .fr.image-reference figcaption {															/* Dark caption in sidebar */
	margin: 0;
	padding: 10px 15px 15px;
	background: #141517;
	color: #fff;
}


/* Mobile only
------------------------------------------------------------------------------*/
@media(max-width: 899px){
	.article-feature .widget figcaption,
	.article-feature .widget h2 { margin: 0 20px }                                /* Full width: Add some space */
  .article-feature .authors .author.author { margin-right: 7px }
	.article-feature .authors {
		padding: 10px 0;
		border: solid #f6f6f6;
		border-width: 1px 0;
		text-align: center;
	}

  .article-feature .authors .update-date {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }

  /* Overwrite .text-body */
  .article-feature > p,
  .article-feature > ul,
  .article-feature > ol,
	.article-feature__body > p,
  .article-feature__body > ul,
  .article-feature__body > ol {
		line-height: 1.525;
		margin: 0 0 1.375em 0;
	}
  .article-feature > .fl.fl.album-reference,
  .article-feature > .fr.fr.album-reference,
	.article-feature__body > .fl.fl.album-reference,
  .article-feature__body > .fr.fr.album-reference { width: 100vw }

	/* Force inline */
  .article-feature > .fln.article-comments,
	.article-feature > .col.external-reference,
	.article-feature > .col.audio-reference,
	.article-feature > .col.tweet-reference,
	.article-feature > .col.quote-reference,
	.article-feature > .col.fact-reference,
	.article-feature__body > .fln.article-comments,
	.article-feature__body > .col.external-reference,
	.article-feature__body > .col.audio-reference,
	.article-feature__body > .col.tweet-reference,
	.article-feature__body > .col.quote-reference,
	.article-feature__body > .col.fact-reference { width: 100%; margin-left: 0 }

	/* Caption next to landscape images */
	.article-feature > .fr.image-reference--F34 .image,
	.article-feature > .fr.image-reference--F11 .image,
	.article-feature__body > .fr.image-reference--F34 .image,
	.article-feature__body > .fr.image-reference--F11 .image {
    display: table;
    margin: auto;
    width: 100vw;
    max-width: 590px;
    box-shadow: 50vw 0 #141517, -50vw 0 #141517;
  }
	.article-feature > .fr.image-reference--F34 figcaption,
	.article-feature > .fr.image-reference--F11 figcaption,
	.article-feature__body > .fr.image-reference--F34 figcaption,
	.article-feature__body > .fr.image-reference--F11 figcaption {
		display: table-cell;
		vertical-align: top;
    padding: 5% calc(3% + 5px);
		width: 55%;
	}

	.article-feature > .fr.fr.image-reference--F169 small,
	.article-feature__body > .fr.fr.image-reference--F169 small { opacity: 1 }		      /* Undo fade credits in sidebar */
  .article-feature > .fr.fr.image-reference--F169 figcaption,
	.article-feature__body > .fr.fr.image-reference--F169 figcaption {                  /* Undo invert if horizontal */
    padding: 8px 15px;
    background: inherit;
    color: #6c6c6c;
  }

	.article-feature .article-feature__intro .article-feature__expand-image-button {
		display: block;
		background: rgba(0,0,0,0.15);
		top: -40px;
		right: 0;
	}

}
@media(max-width: 720px){
  .article-feature .article-feature__intro { padding-top: 7% }                  /* More space on intro */
  .article-feature .article-feature__dates { margin-bottom: 2px }               /* Less space when no images */
  .article-feature .article-update-date {
    letter-spacing: unset;
    text-transform: unset;

    &::before {
      display: none;
    }
  }
}


/* Desktop and up
------------------------------------------------------------------------------*/
@media(min-width: 900px){
	.article-feature {
		padding-left: calc(300px + 6.5vw);
		padding-right: calc(20px + 9vw);
	}

	.article-feature .article-feature__cover {
		position: relative;
		.article-feature__expand-image-button {
			position: absolute;
			display: block;
			bottom: 10px;
			left: 30px;
		}

	}

	.article-feature .article-feature__intro {
		text-align: left;
		padding: 40px 65px 0;
		width: calc(100% + 17vw);
		margin: -7vw 0 0 -8vw;
	}

  .article-feature .authors .update-date {
    position: relative;
    padding-left: 1rem;

    &::before {
      content: '';
      width: 1px;
      display: block;
      height: 100%;
      background-color: currentColor;
      position: absolute;
      left: 0.5rem;
    }
  }

	.article-feature__intro::before,																							/* Intro shadow longer */
	.article-feature__intro::after { height: 200px; }
  .article-feature__footer .update-date::before { content: ', ' }
  .article-feature__footer .article-feature__dates {
    float: left;
    text-transform: none;
    letter-spacing: 0;
    line-height: 20px;
  }

	/* Full width */
	.article-feature > .fc.fc,
	.article-feature__body > .fc.fc {
		margin-left: calc(50% - 48.75vw - 140px); 																	/* 50% - 50vw - padLeft/2 + padRight/2 */
		padding: 0 calc(12vw - 84px);                                               /* Add some space */
	}

	/* Sidebar */
	.article-feature > .fr.fr,
	.article-feature__body > .fr.fr {
		float: left;																																/* Take out of article flow */
		clear: left;							  																								/* Put under previous sidebar element */
		margin-left: calc(-280px - 6.5vw);																					/* Align width sidebar */
		margin-top: .4em;																														/* Align with text */
		width: 280px;
	}

	/* Inline */
	.article-feature > .fl.fl,
	.article-feature > .fln.fln,
	.article-feature > .fc.fact-reference,
	.article-feature > .fr.video-reference,
	.article-feature > .col.audio-reference,
	.article-feature > .col.quote-reference,
	.article-feature__body > .fl.fl,
	.article-feature__body > .fln.fln,
	.article-feature__body > .fc.fact-reference,
	.article-feature__body > .fr.video-reference,
	.article-feature__body > .col.audio-reference,
	.article-feature__body > .col.quote-reference {
		clear: none;																																/* Allow sidebar */
		width: 100%;
		margin-left: 0;
		padding-left: 0;
		padding-right: 0;
	}

	/* Inline popout */
  .article-feature > .fln.video-reference,
	.article-feature > .fln.vimeo-reference,
	.article-feature > .fln.youtube-reference,
	.article-feature > .fln.gallery-reference,
	.article-feature > .fl.image-reference--F169,
	.article-feature > .fl.image-reference--F163,
	.article-feature > .fl.audio-reference,
	.article-feature > .fl.video-reference,
	.article-feature > .fr.video-reference,
	.article-feature > .fl.atlas-reference,
	.article-feature > .fl.album-reference,
	.article-feature__body > .fln.video-reference,
	.article-feature__body > .fln.vimeo-reference,
	.article-feature__body > .fln.youtube-reference,
	.article-feature__body > .fln.gallery-reference,
	.article-feature__body > .fl.image-reference--F169,
	.article-feature__body > .fl.image-reference--F163,
	.article-feature__body > .fl.audio-reference,
	.article-feature__body > .fl.video-reference:is(:has([data-aspect-ratio="16x9"])),
	.article-feature__body > .fr.video-reference:is(:has([data-aspect-ratio="16x9"])),
	.article-feature__body > .fl.atlas-reference,
	.article-feature__body > .fl.album-reference,
	.article-feature__body > .fl.remoterenderedcontent-reference:is(:has(.widget.should-popout)),
	.article-feature__body > .fl.inlinesanitycontent-reference:is(:has(.widget.should-popout)) {
		width: calc(100% + 13vw);																										/* .article-feature padLeft -2vw + padRight */
		margin-left: -4vw;																													/* .article-feature padLeft -2vw */
	}

	/* Content */
  .article-feature .quote { margin-left: -2.5em }																/* Align quote symbol */
	.article-feature .table figure { margin: 0 -8px } 														/* Align table with text-body */
	.article-feature .fl .fact,
	.article-feature .fln .fact { margin: 0 -20px 1em }														/* Align fact with text-body */
  .article-feature .authors:not(.authors--expandable) .author {									/* Authors in row */
    margin-bottom: 15px;
  }

  /* Cover fullscreen icon */
  .article-feature__cover.has-picture::before { background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2015%2015%22%20width%3D%2221%22%20height%3D%2221%22%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-linecap%3D%22round%22%20d%3D%22M8.5%206.5L13%202M2%2013l4.5-4.5m-.5%205H1.5V9M9%201.5h4.5V6%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E') }
}
@media(min-width: 1400px){
	.article-feature .article-feature__intro h1 { font-size: 70px }
  .article-feature .article-feature__intro {
		width: calc(100% + 9vw + 120px);
    margin-top: -100px;
		margin-left: -130px;
	}
}


/* Cover
------------------------------------------------------------------------------*/
.article-feature__cover.fc.fc {
	float: none;
	padding: 0;
	margin-top: 0;
	margin-bottom: 0;
	background: linear-gradient(rgba(0,0,0,.05), transparent);
	min-height: calc(50px + 7vw);
	max-height: calc(100vh - 100px);
	overflow: hidden;
}
.article-feature__cover.fc .video-title,
.article-feature__cover.fc .album-icon use,
.article-feature__cover.fc figcaption,
.article-feature__cover.fc .video-metadata::before { display: none }

.article-feature__cover.fc .video-hud,																					/* Overlay */
.article-feature__cover.fc .album-overlay,																			/* Overlay */
.article-feature__cover.fc .youtube-player,																			/* maxHeight */
.article-feature__cover.fc .vimeo-player,																				/* maxHeight */
.article-feature__cover.fc .media-player-container {  													/* maxHeight */
	top: 0;
	border: 0;
	width: 100%;
	height: 100%;
	max-height: calc(100vh - 100px);
	background: linear-gradient(transparent, rgba(0,0,0,.5));
}
.article-feature__cover.fc .video-status,																				/* Icon */
.article-feature__cover.fc .album-icon {
	position: absolute;
	left: 50%;
	bottom: 50%;
	margin: -22px -42px;
	border: 20px solid;
	border-radius: 50%;
	background-color: currentColor;
	filter: invert(1);
	opacity: .8;
}
.article-feature__cover.fc .album-icon { background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%22-3%20-2%2020%2020%22%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%23000%22%20stroke-linecap%3D%22round%22%20d%3D%22M1%2010l3-3%204%205m-1-2l2-2%204%204.5%22%2F%3E%3Cellipse%20fill%3D%22none%22%20stroke%3D%22%23000%22%20cx%3D%2211.5%22%20cy%3D%225%22%20rx%3D%221.5%22%20ry%3D%221.5%22%2F%3E%3C%2Fsvg%3E') }
.article-feature__cover.fc .album-overlay .caption,															/* Caption */
.article-feature__cover.fc .video-metadata {
	position: absolute;
	text-align: center;
  top: 50%;
  left: 0;
  right: 0;
  margin: 22px 15px;
  font-weight: 700;
}
.article-feature__cover.fc .gallery {                                           /* Gallery */
	margin: auto;
	width: 133vh;
	max-width: 100%;
	box-shadow: 133vh 0 0, -133vh 0 0;
}


/* Cover Picture + Fullscreen
------------------------------------------------------------------------------*/
.article-feature__cover .picture {
  max-height: inherit;
  height: inherit;
  overflow: visible;
  transform: translateY(50%);
}
.article-feature__cover .picture img {
  display: block;
  margin: auto;
  width: 100%;
  transform: translateY(-50%);
}

.article-feature__cover.has-picture { cursor: pointer; position: relative }     /* Indicate click */
.article-feature__cover.has-picture::before {                                   /* Prepare icon */
  content: '';
  position: absolute;
  z-index: 9;
  top: 20px;
  right: 20px;
  width: 21px;
  height: 21px;
  background-position: contain;
  background-repeat: no-repeat;
  opacity: .7;
}
.article-feature__cover.fc.fc.is-fullscreen {
  cursor: pointer;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background: #212121;
}
.article-feature__cover.fc.is-fullscreen figcaption {                           /* Overlay caption */
  display: block;
  position: absolute;
  z-index: 9;
  left: 0;
  right: 0;
  bottom: 50%;
  padding: 4% 2% 2%;
  background: linear-gradient(transparent, #212121);
  color: #fff;
}

@media(max-width: 899px){ // mobile (image aspect is 1x1)
	.article-feature__cover.is-fullscreen img { max-width: 95vh; max-height: 95vh }
}

@media(min-width: 900px){
// F169 aspect is 165:93.  max-width, based on max-height 95vh is therefore 95 x 165 / 93 vh = 168.55vh
	.article-feature__cover.is-fullscreen img { max-width: 168.55vh; max-height: 95vh } /* Center image */
// F163 (low) aspect is 320:83.  max-width, based on max-height 95vh is therefore 95 x 320 / 83 vh = 366.265vh
	.article-feature__cover.is-fullscreen .article-feature__cover--low-aspect img {max-width: 366.265vh;}
}

.article-feature__cover.is-fullscreen::before { background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2015%2015%22%20width%3D%2221%22%20height%3D%2221%22%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-linecap%3D%22round%22%20d%3D%22M9%206l5-5M1%2014l5-5m7-2.5H8.5V2M2%208.5h4.5V13%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E') }
.article-feature__cover.is-fullscreen .article-feature__expand-image-button {
	display: none;
}

.article-feature~section .universe .pagination {
	display: none;
}
