@require '../../common/css/mixins';

// --- DARKMODE ---
html.darkmode {
	.compilation.plug.emphasis-high {
		.plug-teaser {
			border-color: $nrk-color-warm-pink-800;
		}
	}

	.compilation-navigation-list li:first-child.half {
		border-color: $darkmode-border;
	}

  .compilation-title {
    color: $darkmode-text;
  }

  .compilation-status {
    color: $darkmode-text-byline;
  }

  .compilation-summary {
    background-color: $darkmode-background;
    color: $darkmode-text;
    .compilation-summary-title {
      color: $darkmode-text;
    }
  }

  .compilation-aside {
    background-color: $darkmode-background;
    color: $darkmode-text;
		border: none;

		&.emphasis-high {
			.compilation-aside-image {
				border: none;
			}
			.compilation-aside-teaser {
				&:before {
					background: radial-gradient(transparent 13%, $nrk-color-warm-pink-800 23%,$nrk-color-warm-pink-800 50%,transparent 60%) 0/15px 15px no-repeat;
				}
			}
		}
  }

  .group.floor.negative {
    background: $darkmode-body-background;
  }
  .negative .compilation.brief .plug-teaser > .compilation-link {
    color: $nrk-color-core-blue-200;
  }

  .negative .compilation.plug a:hover .compilation-link {
    color:	$nrk-color-core-blue-200 !important;
  }

  .emphasis-high .status-line .status-line-top {
    background-color: $nrk-color-warm-pink-800;
  }

  .compilation-navigation {
    border-color: $darkmode-border;
  }

  .compilation-summary:before {
    background: $darkmode-border;
  }

  .compilation-navigation-list li {
    border-color: $darkmode-border;
    a {
      color: $darkmode-text-byline;
    }
  }

  .compilation.rich {
    .compilation-media-wrapper {
      background: $darkmode-body-background;
    }
  }

	.flow-content > .col > .compilation.plug {
		border-color: $darkmode-border-light;
	}


  @media screen and (min-width: 720px) {
    .compilation.emphasis-high {
      .compilation-status {
        display: block;
        transition: color 1000ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
        color: $darkmode-text;
      }
    }
  }
}
// --- DARKMODE END ---

/* hide status line by default */
.compilation .status-line {
	overflow: hidden;
	max-height: 0;
	z-index: 1000;
	transition: 1s;
}

.compilation.rich {
	.compilation-media-wrapper {
		background: #26292a;
		line-height: 0px;
	}

	.compilation-attachment.video {
		margin-top: 0;
		margin-bottom: 0;
	}
}

/* compilation displayed as link (ie. reference ) */
.compilation-link {
	text-transform: uppercase;
	font-size: 13px;
	letter-spacing: 0.03em;
}
.compilation-link a {
	color: $text-color;
  opacity: 0.8;
	border-bottom: 1px solid transparent;
}
.compilation-link a:hover {
	border-color: $text-color;
  opacity: 0.8;
}

/* embedded article plug */
.compilation.plug .plug-image {
	position:relative;
	float: left;
	width: 95px;
	margin-top:0;
	margin-right: 10px;
	margin-bottom:0;
}

// videos in compilation plugs
.compilation.plug .video {
	pointer-events:none;
}

.go-large .compilation.plug .video {
	pointer-events:auto;
}

.compilation.plug .video.plug-image:after {
	fontSize(11px, 24px);
	line-height: 2.5;
	letter-spacing: 0.02em;
	display: block;
	position: absolute;
	z-index: 1;
	top:10px;
	right: 15px;
	padding: 0 10px 0 24px;
	content: 'SE VIDEO';
	color: #fff;
	background: #26292A url(../../common/img/white-plug-sprite.png) no-repeat;
	background-position: 9px -82px;
	background-color: rgba(38,41,42,0.8);
}

.compilation.plug .video.plug-image[data-audio-as-video="true"]:after {
	content: 'HØR LYD';
}

.compilation.plug.compilation-audio-live .plug-image:after {
	background-position: 8px -332px;
}

.compilation.plug.compilation-video-live .video.plug-image:before {
  background-color: #ff5d46;
  border-radius: 50%;
  content: '';
  height: .625rem;
  right: 6rem;
  position: absolute;
  top: 0.625rem;
  width: .625rem;
  z-index: 3;
}

.compilation.plug.compilation-video-live .video.plug-image:after {
	content: 'Direkte';
  background-color: #fff;
  color: #061629;
  font-size: 1.125rem;
  font-weight: 800;
  letter-spacing: -.01rem;
  line-height: 131%;
  padding: .075rem .4rem;
}

.compilation.plug.compilation-video-live .video.plug-image[data-audio-as-video="true"]:after {
	content: 'HØR DIREKTE';
}

.compilation.plug.compilation-media-pending .video.plug-image:after {
	content: 'DIREKTE SNART';
	color:#E51C30;
	background-color: #fff;
	background-image: url(../../common/img/red-plug-sprite.png);
}

.compilation.plug.compilation-media-pending .video.plug-image[data-audio-as-video="true"]:after {
	content: 'HØR DIREKTE SNART';
}

.compilation.plug .video-hud {
	display:none;
}

.go-large .compilation.plug .video-hud {
	display:block;
}

.go-large .compilation.plug .video.plug-image:after {
	display:none;
}

// overrides for compilations in flow
.flow-content > .col > .compilation.plug {
	margin: 0;
	padding: 10px;
	border-bottom:1px solid #6C6C6C;
}

.flow-content > .col:last-child > .compilation.plug {
	margin-bottom: 10px;
	border: none;
}

.flow-content .compilation-media .video {
 padding-top: 0;
 padding-bottom: 0;
}

.compilation.plug {
	position: relative;
}

.compilation.plug figcaption {
	display:none;
}


/* plug arrow pseudo element */
.compilation.plug .plug-teaser:after {
	display: none;
}

.compilation.plug a:hover {
	.compilation-link {
		border-bottom-color: inherit;
	}
	.plug-svg-arrow {
	  color: #00b9f2;
	}
}

.negative .compilation.plug a:hover .compilation-link {
	border-bottom-color: #c8c8c8;
}

.compilation.plug .plug-svg-arrow {
	display: block;
	position: absolute;
	right: 15px;
	top: 50%;
	margin-top: -11px;
	width: 9px;
	height: 23px;
	color: #fff;
}

/* XXX: refactor; */
.compilation.brief .plug-teaser {
	padding: 0px 30px 0 0;
	margin-left: 63px;
}

.compilation.brief .plug-teaser > .compilation-link {
	display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// override color when on dark background
.negative .compilation.brief .plug-teaser > .compilation-link {
	color:#c8c8c8;
}

.negative .compilation.plug a:hover .compilation-link {
	color:#fff !important;
}

.compilation-circle {
	display: inline-block;
	position:relative;
	width: 4px;
	height: 4px;
	border-radius: 100%;
	border-width: 4px;
	border-style: solid;
	margin-right:5px;
	top:1px;
}

.compilation-circle--live {
	top: -1px;
	width: 0;
	height: 0;
	border-width: 3px;
	margin-right: 12px;
  color: #ff7461;
}

.compilation-circle--live::before {
	content: '';
	position: absolute;
	width:  12px;
	height: 12px;
	margin: -9px;
	border: inherit;
	border-radius: inherit;
	animation: compilation-circle--live 2s infinite;
	transform: scale(.2);
}

@keyframes compilation-circle--live {
	to { transform: scale(1); opacity: 0 }
}

.compilation.plug .compilation-circle {
	border-color:inherit;
}

.compilation.plug .title {
	fontSize(15px, 19px);
	margin-top: 0;
	margin-bottom: 5px;
	top: 0;
}

.compilation.plug .plug-footer {
	display: none;
}

.compilation .group.floor {
	padding:0;
	margin-top: 0 !important;
}

.surrogate-reference.sAUTO .compilation .group.floor {
	margin-bottom: 24px !important;
}

.surrogate-reference.sAUTO .compilation .group.floor .status-line {
	/*margin-top:10px;
	margin-bottom:-10px;*/
}

.compilation-content .compilation-top {
	margin-top:24px;
}

.compilation-title {
	font-size: 25px;
	color: #26292A;
	margin: 0;
	font-weight: bold;
	line-height: 1.2em;
}

.compilation-media {

	.status-line {
		position:absolute;
		top:0;
		left:0;
		width:5px;
		height:100%;
		transform:rotate(180deg);
		transition: none;
	}

	.status-line-top,
	.status-line-bottom {
		height:100%;
	}

	.status-line-bottom {
		width:2px;
	}

	figcaption {
		position:absolute;
		bottom:0;
		right:0;
		width:auto;
		padding: 3px 5px;
		color:#F6F6F6;
		background: #26292A;
		background: rgba(0,0,0,0.55);

		small {
			line-height:1;
			margin:0;
			text-transform:none;
		}
	}
}

// Override format for images
.compilation-image {
	position:relative;
	max-width:1800px;
	margin: 0 auto;
}

/* decription title/text grid element */
.compilation-summary {
	position:relative;
	background:#F6F6F6;
	padding:10px;

	ul {
		margin: 0 0 .8em 1.2em;
  		padding: 0;
  		li {
  			margin: 0 0 .5em 0;
  		}
	}
}

.compilation-summary-decoration {
	display:block;
	width: 5px;
	height: 20px;
	position:absolute;
	top:15px;
	bottom:0;
	left:-5px;
}

.compilation-summary-title {
	display: inline-block;
	fontSize(14px, 16px);
	color:#26292A;
	margin:0 4px 0 0;
	font-weight: 600;
	text-transform: uppercase;
}

.compilation-summary-title:after {
	content: ':';
	clear: both;
}

/* description text content */
.compilation-description {
	fontSize(13px, 16px);
	font-weight: 500;
	color: #26292A;
}

.compilation-description:after {
	clear: both;
}

.compilation-description p {
	margin: 0 0 8px 0;
	line-height: 1.4;
}

.compilation-description p:last-child {
	margin-bottom: 0;
}

.compilation-description ul {
	margin: 5px 0 0 15px;
	list-style-type: square;
	padding-left: 0;
}

.compilation-description li {
	margin-bottom: 12px;
}

/* navigation */
.compilation-navigation {
	margin: 0 0 24px 0;
	border-top: 1px solid #E0E0E0;
}

.compilation-navigation-list {
	padding: 0;
	margin:0;
}

.compilation-navigation-list li {
	position: relative;
	width:100%;
	box-sizing:border-box;
	border-bottom: 1px solid #E0E0E0;
}

.compilation-navigation-list li:not(.half) {
	display: table;
  	clear: both;
}

.compilation-navigation-list li.half {
	width:50%;
	float:left;
}
.compilation-navigation-list li:first-child.half {
	border-right: 1px solid #E0E0E0;
}

.compilation-navigation-list li a {
	fontSize(13px, 15px);
	padding: 3px 10px 10px 10px;
	display: block;
	color: rgba(38, 41, 42, 0.8);
}

.compilation-navigation-list li a:after {
	content: '';
	display: inline-block;
	height: 20px;
	width: 20px;
	background: transparent url(img/compilation-sprite.png) no-repeat 0 -24px;
	position: relative;
  	top: 1px;
  	left: 3px;
}

/* hide superfluous compilation title inside bulletins */
.compilation .bulletin .compilation-link {
	display: none;
}

.compilation-footer {
	clear: both;
}

/* byline */
.compilation-authors {
	display: none;
}
.compilation-authors.show {
	display: block;
	margin-top: 12px;
	margin-bottom: -9px;
	line-height: 1.2;
}
.compilation-authors .author,
.compilation-authors .author__body { display: inline }
.compilation-authors .author__role,
.compilation-authors .author__twitter { display: none }
.compilation-authors .author:first-child::before { content: 'På vakt nå: '}
.compilation-authors .author:nth-last-child(n+2)::after { /*all but last*/
	content: ', ';
	display: inline-block;
	margin-left: -4px;
}

.compilation-status {
	fontSize(12px, 16px);
	color:#fff;
	font-weight:normal;
	text-transform:uppercase;
	display:inline-block;
	margin:0 0 4px 0;
}

.compilation-status {
	display:none;
}

.compilation-meta {
	display:inline-block;
}

/* widget spacing */
.compilation-content .container-widget-content {
	margin-bottom: 24px;
}

.s10 > .compilation-articles-container > .relation:first-child {
	width:100%;
}

// hack pga. feil spacing i manuell flyt bør fikses
.compilation-articles .compilation-articles-container {
	word-spacing: normal;
}

// override share buttons
.compilation-navigation-group {
	position:relative;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {

    .compilation.plug .plug-teaser:after {
    	background-size: 10px auto;
    	background-position: 0 -60px;
    }

    .compilation-navigation > li:after {
    	background-size: 20px auto;
    	background-position: 0 -62px;
    }
}

@media screen and (max-width: 720px) {

	.compilation-summary {
		line-height: 0px;
		margin:0 !important;
	}

	.compilation-summary:before {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:1px;
		background:#E0E0E0;
		content:' ';
	}

	.compilation-attachment {
		margin:0;
	}

	.flow-relation.compilation-reference:not(.go-large) .compilation.plug .video.plug-image:after {
		top:auto;
		right: 0;
		bottom:0;
	}

	.compilation.plug.compilation-video-live .video.plug-image:after {
		content: 'DIREKTE';
	}

	.compilation.plug.compilation-media-pending .video.plug-image:after {
		content: 'SNART';
	}

	.compilation.plug.compilation-video-live .video.plug-image[data-audio-as-video="true"]:after {
		content: 'HØR NÅ';
	}

	.compilation.plug.compilation-media-pending .video.plug-image[data-audio-as-video="true"]:after {
		content: 'HØR SNART';
	}

	.compilation.brief .plug-teaser > .compilation-circle {
		display: none;
	}

	// Override video in compilations in flow
	.flow-relation.compilation-reference.go-large .compilation.plug {
		padding:0;
		//margin-top:-10px;
	}

	.flow-relation.compilation-reference.go-large .compilation.plug .image {
		margin: 0;
  		width: 100%;
  		float: none;
	}

	.flow-relation.compilation-reference.go-large .compilation.plug .plug-teaser {
		margin: 0;
		padding:10px 25px 10px 10px;
		position:relative;
		min-height: 54px;
	}

	.flow-relation.compilation-reference.go-large .compilation.plug .plug-teaser .title {
		fontSize(18px,22px);
	}

	.container-widget > .col > .compilation-authors.show {
		margin: 20px 10px 5px 10px;
	}
}


@media screen and (max-width: 720px) {

	.go-large .compilation.brief .plug-teaser .compilation-link {
		margin-top: 4px;
	}

	.compilation.brief .plug-teaser {
		display: flex;
		flex-direction:column;
	}

	.compilation.brief .compilation-link {
		order: 1;
		margin-bottom: 3px;
	}

	.compilation.brief .title {
		order: 2;
		margin-bottom: 0;
	}

	.compilation-summary-title {
		display: inline;
		margin: 0;
	}

	.compilation-description p:first-child {
		display: inline;
	}

	.compilation-description {
		display: inline;
	}

	.compilation-description p {
		margin: 8px 0;
	}



}

@media screen and (min-width: 720px) {

	/* Override format for images */

	.compilation-image .responsive-img.F169/*,
	.compilation-image .plugin*/ {
		padding-bottom:40%;
	}

	.compilation-image .responsive-img.F169 img {
		transform: translateY(-8.11%);
		height:auto;
	}


	/* widget spacing */
	.compilation-content .container-widget-content {
		margin-bottom: 30px;
	}

	.compilation-content .compilation-top {
		margin-top: 50px;
		margin-bottom: 20px;
	}

	.compilation-sidebar, .compilation-articles {
		margin-top: 0;
	}


	/* background raster styling */
	.surrogate-reference.sAUTO .compilation .group.floor {
		margin-bottom: 30px !important;
	}

	.surrogate-reference.sAUTO .compilation .group.floor .status-line {
		/*margin-top:30px;
		margin-bottom:-30px;*/
	}

	.compilation .group.floor {
		margin-bottom: 30px !important;
		padding-bottom: 0;
	}

	/* compilation plugs inside flow */
	.flow-content > .col > .compilation.plug,
	.flow-content > .col:last-child > .compilation.plug {
		margin: 0 15px 30px 15px;
		padding: 0;
		border-bottom: none;
		background-color: transparent;
	}

	.compilation-media {
		position:relative;
		margin: 0 auto;
  		float: none;

		.status-line {
			left:auto;
			right:0;
			width:7px;
			transform:none;
		}

		.video {
			padding-top: 30px;
			padding-bottom: 30px;
		}

		figcaption {
			bottom:0px;
			right:0px;
		}
	}

	.compilation-navigation-group {
		display:flex;
		flex-direction:column;
	}

	.compilation-summary {
		order:2;
	}

	.compilation-navigation {
		order:3;
		display:none;
	}

	.compilation-meta {
		position:relative;
		margin-top:-21px;
		z-index:1;
	}

	.compilation.plug .compilation-circle {
		float: left;
		margin: 2px 5px 4px 0;
		transition: border-color 1000ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
	}

	.compilation.emphasis-high .compilation-status {
		color: #26292a;
		display: block;
		font-weight: 600;
		letter-spacing: 0.03em;
	}

	.compilation.plug.emphasis-high .plug-teaser {
		border-color:#FF7461;
	}

	/* bylines */
	.compilation-authors.show {
		display: inline-block;
		vertical-align: top;
		margin: 9px 0;
	}
	.compilation-authors .author + .author { margin-left: 14px }
	.compilation-authors .author, .compilation-authors .author__body { display: inline-block; margin-bottom: 0 }
	.compilation-authors .author::before,
	.compilation-authors .author::after { display: none; font-size: 0 }
	.compilation-authors .author__body::after {
		content: 'På vakt nå';
		display: block;
	}
	.singleton-reference .compilation-authors .author + .author { /* nyhetssenteret */
		display: block;
		margin-top: 15px;
		margin-left: 0;
	}

	/* title/mavigation styling */
	.compilation-title {
		fontSize(52px, 64px);
		display: inline-block;
		margin: 0 30px 0 0;
	}

	.compilation-summary {
		padding:15px 20px;
		margin-bottom:30px;
	}

	.compilation-summary-title:after {
		content: '';
	}

	.compilation-description {
		fontSize(15px, 21px);
	}

	.compilation.brief .plug-teaser {
		margin-left: 0;
		padding:0;
		position: relative;
		line-height: 1;
	}

	.compilation.brief .plug-teaser > .compilation-link {
		display: inline;
		white-space: normal;
		border-bottom-width:1px;
		border-bottom-style:solid;
		border-bottom-color:transparent;
		position:relative;
		top: 0;
	}

	.compilation.plug .plug-teaser:after {
		display:none;
	}

	.compilation.plug .plug-svg-arrow {
		display: none;
	}


	.compilation.plug .plug-image {
		width: 100%;
		height: auto;
		display: block;
		margin-bottom: 10px;
		float: none;
	}

	.compilation.plug .plug-image img {
		height: auto;
		width: 100%;
		margin-left: 0;
	}

	.compilation.plug > a:after {
		top: 0;
		right: 0;
	}

	.compilation.plug .title {
		fontSize(22px, 25px);
		margin-bottom: 8px;
	}

	/* streams of bulletins of 18 columns should be displayed in 12 columns */
	.compilation .s18 > .container-widget-content > .stream-content,
	.compilation .s18 > .container-widget-content > .stream-footer {
		max-width: 66.666666666%;
		margin: 0 auto;
	}

	.flow-relation.compilation-reference {
		//transition: all 1000ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
	}
	// compilations references in flow
	.flow-relation.compilation-reference.go-large {
		box-sizing: border-box;
		float: left;
		width: 66.5%;
		padding-right: 50px;
	}

	.flow-relation.compilation-reference.go-large .compilation.plug .title {
		fontSize(34px, 40px);
	}
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .compilation.plug.compilation-video-live .plug-image:after {
    	background-size: 16px auto;
    	background-position: 9px -138px;
    }
    .compilation.plug.compilation-audio-live .plug-image:after {
    	background-position: 8px -181px;
    }
}


/* Compilation aside (card)
-------------------------------------------------------*/
.compilation-aside {
	border: 1px solid #dedede;
	color: #707070;
	background: #fff;
}
.compilation-aside a {
	color: inherit!important;
}
.compilation-aside:hover {
	border-color: #c8c8c8;
}
.compilation-aside:hover .title {
	text-decoration: underline!important;
}

.compilation-aside-teaser {
	overflow: hidden;
	padding: 12px;
	padding-bottom: 10px;
}
.compilation-aside-teaser::before {
	display: block;
	font-size: 12px;
	letter-spacing: .07em;
}
.compilation-aside-teaser::before {
	content: 'MER OM';
}
.compilation-aside-point {
	fill: currentColor;
	float: right;
	width: 30px;
	height: 110px;
}
.compilation-aside-image {
	float: left;
	clear: none;
	overflow: hidden;
	border-radius: 50%;
	height: 90px;
	width: 90px;
	margin: 10px 0 10px 5px;
}
.compilation-aside-image .responsive-img {
	max-width: none;
	margin: 0 -39%;
	padding: 50%!important;
}
.compilation-aside-title {
	font-size: 18px;
	margin: 4px 0;
}
.compilation-aside-description {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	color: inherit;
	font-size: 13px;
}

// need .stream.lean here for sufficent specifity to override
.compilation-aside-teasers.stream.lean {
	background: #ffffff;

	// inherited from .text-body
	a {
		border: none !important;
	}

	:empty {
		display: none;
	}

	.teaser.reference {
		border-left: 1px solid #dedede;
		border-right: 1px solid #dedede;
		overflow: hidden;

		&:last-child {
			border-bottom-color: #dedede;
		}
	}

	.teaser.reference.emphasis-high {
		border-color: #FF7461;
	}
}

/* Compilation aside (card emphasis)
-------------------------------------------------------*/
.compilation-aside.emphasis-high {
	color: #fff;
	background: #061629;
	border-color: #061629;
}
.compilation-aside.emphasis-high .compilation-aside-teaser::before {
	content: 'VI FØLGER NÅ';
	padding-left: 20px;
	background: radial-gradient(transparent 13%,#FF7461 23%,#FF7461 50%,transparent 60%) 0/15px 15px no-repeat;
}
.compilation-aside.emphasis-high .compilation-aside-title {
	color: #fff;
}

.compilation-aside.emphasis-high .compilation-aside-image {
	border: 3px solid #FF7461;
}

/* Compilation aside (card large)
-------------------------------------------------------*/
.sg6 > .compilation-aside { background-position: 100% 50px }
.sg6 > .compilation-aside .compilation-aside-point { height: 135px }
.sg6 > .compilation-aside .compilation-aside-image { width: 115px; height: 115px }
.sg6 > .compilation-aside .compilation-aside-title { font-size: 20px; margin: 6px 0 }
.sg6 > .compilation-aside .compilation-aside-description { max-height: 2.8em }

@media screen and (min-width: 1380px) {
	.compilation-stream { min-height: 180px }
	.compilation-content .compilation-top { margin-top: 60px; margin-bottom: 50px }
	.compilation-sidebar, .compilation-articles { margin-top: 0 }
}
