.election-header {
	height: 60px;
	background: white url(img/header-background.png) no-repeat center center;
	border-bottom: 1px solid #d9d9d9;
}

.election-header h1 {
	margin: 0;
	text-indent: -9999em;
	width: 88px;
	height: 60px;
	margin-left: 10px;
	background: transparent url(img/logo.png) no-repeat center center;
	background-size: 65px auto;
}

.election-header header > div {
	height: 60px;
	/*background: transparent url(img/colors.png) no-repeat 120px center;*/
	/*background-size: 165px auto;*/
}

.election-header h1 a {
	display: block;
	height: 100%;
}

@media screen and (min-width:720px) { 
	.election-header {
		height: 90px;
	}

	.election-header h1 {
		margin-left: 20px;
		height: 90px;
		background-size: auto auto;
	}

	.election-header header > div {
		height: 90px;
		background-position: 170px center;
		background-size: auto auto;
	}

}