figure.audio {
	position: relative;
}

figure.audio .media-player-container {
	background: #fff;
	overflow: hidden;
	padding: 13px 13px 13px 15px;
}

figure.audio .media-player {
	height: 0px;
	transition: height .3s, opacity .3s;
	-moz-transition: height .3s, opacity .3s;
	-webkit-transition: height .3s, opacity .3s;
	-o-transition: height .3s, opacity .3s;
}

figure.audio .media-player {
	background: transparent;
	padding-left: 75px;
}

figure.audio .alert {
	margin: 0; // override default margins for alerts, as the surrounding container should handle that
}

figure.audio .alert.description::before {
	margin-left: calc(50% - 12px);
}

figure.audio .alert.description .message {
	margin-top: 30px;
	padding: 0 24px;
	text-align: center;
}