.status-line {
	position:relative;
	width:100%;
}

.status-line-top {
	width:100%;
	height:6px;
}

.status-line-bottom {
	position:absolute;
	top:0;
	width:100%;
	height:2px;
	background:rgba(0,0,0,0.5);
}

.emphasis-high .status-line {
  display: block;
  max-height: 900px;
}

.emphasis-high .status-line .status-line-top {
	background-color: #FF7461;
}

@media screen and (min-width: 720px) {
	.status-line-top {
		height:9px;
	}
	.status-line-bottom {
		height:3px;
	}
}
