@require "../../common/css/mixins";

MENU_BAR_HEIGHT = 46px;
MENU_LINK_SELECTED = 3px;

// --- DARKMODE ---
html.darkmode {
   .menu-bar {
      background-color: $darkmode-body-background;
      box-shadow: none;
      border-bottom: 1px solid $darkmode-border;
      &.negative {
        .menu__title:after {
          background: none;
        }
        .menu__link {
          color: $darkmode-text;
        }
      }
    }

    .menu__link, .menu__title a {
      color: $darkmode-text;
    }
    
    .menu__scroller {
      background: linear-gradient(to right, rgba(0,0,0,0), $darkmode-body-background 50%);
    }
}

// This file is used by abb, but without the class .menu
.menu ul {
	list-style: none;
	margin: 0;
	padding: 0
}
.menu li {
	display: inline
}

// Hide masthead when html.no-header is present
html.no-header .menu-bar {
	display: none;
}
.menu-bar {
  background: $color-white;
  overflow-y: hidden;
  box-shadow: 0 1px 6px 0 rgba(0,0,0,0.11);
}
.menu-bar.negative {
  background-color: $nrk-color-core-blue-950;
  color: $color-white;
  -webkit-font-smoothing: auto;
}
.menu-bar .group {
	padding-top: 0 !important;
}
.menu {
  white-space: nowrap;
  height: MENU_BAR_HEIGHT * 2;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  @media $mq-medium {
    height: MENU_BAR_HEIGHT;
  }
}
.menu--empty {
  height: MENU_BAR_HEIGHT + 2;
  overflow: hidden;
}
.menu--empty h2 ~ * {
  display: none;
}

.menu__title {
  padding-right: 18px;
  padding-left: 18px;
  margin: 0 0 0 -18px;
  font: 1.125rem FONT-FAMILY-NRK-SANS;
  font-weight:  600;
  line-height: MENU_BAR_HEIGHT !important;
  text-rendering: optimizelegibility;
  color: currentColor;

  @media $mq-medium {
    display: inline;
    float: left;
  }
  &:after {
    content: ' ';
    display: block;
    height: 1px;
    background: currentColor;
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0.1;
    z-index: 5;

    @media $mq-medium {
      content: none;
    }
  }
}
.menu-bar.negative .menu__title {

  &:after {
    background: $color-white;
  }
}
.menu__title a {
  color: $nrk-color-core-blue-950;
}
.menu-bar.negative .menu__title a {
  color: inherit;
}


.menu__time {
  float: left;
  width: 70px;
  margin-right: 0.625rem
  line-height: inherit !important;
}
.menu-bar.negative .menu__time {
  color: $nrk-color-core-blue-50;
}

.menu__scroller {
  position: absolute;
  opacity: 0;
  cursor: pointer; // Required to make clickable in iOS
  position: absolute;
  z-index: 3;
  right: 3px;
  width: 3rem;
  height: MENU_BAR_HEIGHT;
  background: linear-gradient(to right, rgba(255,255,255,0), #fff 50%);
  transition: opacity .5s;

  .icon {
    float: right;
    padding-right: 3px;
    height: MENU_BAR_HEIGHT;
    width: 20px;
  }
}
.menu__scroller--prev {
  transform: scale(-1, 1);
  float: left;
  position: relative;
  margin-right: -3rem;
}
.menu-bar.negative .menu__scroller {
  background: linear-gradient(to right, rgba(38, 41, 42, 0), $nrk-color-core-blue-950 50%);
  color: $nrk-color-gray-200;

  &:hover {
    color: $nrk-color-core-blue-600;
  }
}
.menu__list {
  height: 100px; // Hide scrollbar
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.menu__item {
  padding-right: 1rem;
  margin-right: -3px;
  display: inline;
}
.menu__link {
  background-color: rgba(0, 0, 0, 0.0);
  padding: 0;
  font-size: 14px;
  line-height: MENU_BAR_HEIGHT + 2px;
  color: $nrk-color-gray-800;
  opacity: 0.8;
  display: inline-block;
}
.menu__link:hover,
.menu__link.selected {
  opacity 1;
}
.menu__link[class*="skin-border"] {
  height: MENU_BAR_HEIGHT - MENU_LINK_SELECTED;
  border-bottom-width: MENU_LINK_SELECTED;
  border-bottom-style: solid;
}
.menu__link.skin-border-hover,
.menu__link.skin-border-hover:hover {
  border-color: rgba(0,0,0,0.0);
}
.menu-bar.negative .menu__link {
  color: $color-white;
}

@media print {
	.menu ul {
		display: none;
	}
}
