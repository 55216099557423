@require "../../common/css/mixins";

figure, figcaption {
  display: block;
  margin: 0;
  padding: 0;
  clear: both;
  overflow: hidden;
}

.image .responsive-img {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  z-index:0;
}

.image .responsive-img.F-placeholder {
  padding-bottom:1px !important;
  width:1px !important;
  margin-top:-1px !important;
}
.image .responsive-img.F169 {
  padding-bottom: 56.25%;
}
.image .responsive-img.F11 {
  padding-bottom: 100%;
}
.image .responsive-img.F916 {
  padding-bottom: 177.77%;
}
/* This is a hack, the actual image format from polpoly/kaleido is actually 16x4.1 => giving us 25.625% */
.image .responsive-img.F163 {
  padding-bottom: 25.85%;
}
.image .responsive-img.F34 {
  padding-bottom: 133.33%;
}
.image .responsive-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image noscript > img {
  width:100%;
  height:auto;
  z-index: 0;
}

.widget figcaption {
  padding: 8px 1px;
  color: $nrk-color-gray-600;
}
.widget figcaption p,
.widget figcaption small  {
  margin: 0;
  padding: 0;
  display: block;
}
.widget figcaption p {
  line-height: 1.3em;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-top: 2px;
  margin-bottom: 6px;
}
.widget figcaption small {
  line-height: 1.3em;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.widget figcaption p + small {
  margin-top: 3px;
}

/* css loaded */
#cssLoaded {
  width: 1px !important;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
}
