.nufal-header {
	height: 60px;
	padding-top: 5px;
}

.nufal-header h1 {
	margin: 0 0 0 -20px;
	text-indent: -9999em;
	width: 71px;
	height: 60px;
	margin-left: 10px;
	background: transparent url(img/logo.png) no-repeat center center;
	background-size: 65px auto;
}

.nufal-header h1 a {
	display: block;
	height: 100%;
}

.nufal-header .grid {
	position: relative;
}

.sapmi-link {
	text-indent: -9999em;
	position: absolute;
	background: transparent url(img/sapmi-logo.png) no-repeat center center;
	background-size: 50px auto;
	height: 50px;
	width: 50px;
	top: 6px;
	right: 10px;
}



@media screen and (min-width:720px) { 
	.nufal-header {
		padding: 20px 20px 0 20px;
		height: 90px;
	}

	.nufal-header h1 {
		width: 125px;
		height: 90px;
		margin-left: 0;
		background-size: auto auto;
	}

	.nufal-header header > div {
		height: 90px;
		background-position: 170px center;
		background-size: auto auto;
	}

	.sapmi-link {
		background-size: auto auto;
		height: 79px;
		width: 79px;
		top: 7px;
		right: 0;
	}

}