.push-button {
	font-size: 0.875rem;
	padding: 12px 16px;
	text-transform: uppercase;
	background-color: transparent;
	border-width: 1px;
	border-style: solid;
	border-radius: 3px;
}

.push-button:no(.skin-border) {
	border-color: #dedede;
}

.push-button.selected {
	color: white;
}

.push-button-wide {
	display: inline-block;
	width: 100%;
}

.button-group {
	overflow: hidden;
}

.button-group > .push-button {
	float: left;
	border-radius: 0;
	border-left: 0;
}

.button-group > .push-button:first-child {
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	border-left: 1px solid #d3d3d3;
}

.button-group > .push-button:last-child {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}