.ntbsport.widget {
  display: none;

  &>div {
    border 1px solid #E0E0E0;
  }
}

// --- DARKMODE ---
html.darkmode .ntbsport.widget {
  [data-style-id="nrk"] {
    > div,
    [data-component-id="team-match-result"] > div,
    [data-component-id="team-match-report"] > div {
      background-color: $darkmode-background;
    }

    [data-component-id="team-match-report"] {
      > div > div, ul li {
        background-color: $darkmode-background;
      }
    }

    [data-component-id="team-lineup"] > div > div:last-of-type > div:last-of-type > div:last-of-type > div > div {
      /* Names of players in team line-up BENCH (below team line-up) */
      > span:first-of-type {
        color: $darkmode-text;
      }

      /* Set color of icon to black, but not cards or hte substitution icon */
      > div svg:not(.card, .css-onkibi) {
        color: black;
      }
    }
  }

  button.chakra-button {
    background-color: transparent;
    color: $darkmode-text;
    border: 1px solid $darkmode-text;
    border-radius: 100px;
  }

  > div > div:last-of-type div {
    color: $darkmode-text-byline;

    svg {
      path, polygon {
        fill: $darkmode-text-byline;
      }
    }
  }

  table tbody tr:hover {
    background-color: $darkmode-body-background;
  }


}