.valg-banner-2015 {
	background: transparent url(img/Valg-distrikt-mobil-1x.png) no-repeat;
	background-size: 100% 100%;
	padding-bottom:14.66%;
	box-sizing:border-box;
	position:relative;
}

.valg-banner-2015.nynorsk {
	background-image: url(img/Valg-distrikt-mobil-1x-NN.png);
}

.valg-banner-2015.sapmi {
	background-image: url(img/Valg-distrikt-mobil-1x-Samisk.png);
}

.valg-banner-2015 a {
	display:block;
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	right:7%;
	box-sizing:border-box;
	padding-left:21%;
	text-decoration:none;
}

.valg-banner-2015.sapmi a {
	padding-left:28%;
}

.valg-banner-2015 a:hover {
	text-decoration:none;
}

.valg-banner-2015 a span {
	position:absolute;
	top:50%;
	transform: translateY(-50%);
	font-size: 14px;
	line-heigth:1;
	color:#fff;
}

.valg-banner-2015 a:hover span {
	color:#E9E9E9;
}

@media screen and (min-width: 360px) {
	.valg-banner-2015 a span { font-size: 16px; }
}

@media screen and (min-width: 400px) {
	.valg-banner-2015 a span { font-size: 18px; }
}

@media screen and (min-width: 440px) {
	.valg-banner-2015 a span { font-size: 20px; }
}

@media screen and (min-width: 520px) {
	.valg-banner-2015 a span { font-size: 24px; }
}

@media screen and (min-width: 600px) {
	.valg-banner-2015 a span { font-size: 28px; }
}

@media screen and (min-width: 720px) {
	.valg-banner-2015 a span { font-size: 22px; }
	.sl12 .valg-banner-2015 a span { font-size: 16px; }
	.sl9 .valg-banner-2015 a span { font-size: 12px; }
}

@media screen and (min-width: 780px) {
	.valg-banner-2015 a span { font-size: 26px; }
	.sl12 .valg-banner-2015 a span { font-size: 18px; }
	.sl9 .valg-banner-2015 a span { font-size: 13px; }
}

@media screen and (min-width: 840px) {
	.valg-banner-2015 a span { font-size: 28px; }
	.sl12 .valg-banner-2015 a span { font-size: 20px; }
	.sl9 .valg-banner-2015 a span { font-size: 13px; }
}

@media screen and (min-width: 900px) {
	.valg-banner-2015 a span { font-size: 30px; }
	.sl12 .valg-banner-2015 a span { font-size: 21px; }
	.sl9 .valg-banner-2015 a span { font-size: 14px; }
}

@media screen and (min-width: 1080px) {
	.sl9 .valg-banner-2015 a span { font-size: 16px; }
}

@media screen and (min-width: 720px) {
	.col:not(.sl9) > .valg-banner-2015 {
		background-image: url(img/Valg-distrikt-desktop-1x.png);
		padding-bottom:10.3%;
	}
	.col:not(.sl9) > .valg-banner-2015.nynorsk {
		background-image: url(img/Valg-distrikt-desktop-1x-NN.png);
	}
	.col:not(.sl9) > .valg-banner-2015.sapmi {
		background-image: url(img/Valg-distrikt-desktop-1x-Samisk.png);
	}
	.col:not(.sl9) > .valg-banner-2015 a {
		padding-left:19.3%;
		right:5%;
	}
	.col:not(.sl9) > .valg-banner-2015.sapmi a {
		padding-left:22%;
	}
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
	.valg-banner-2015 {
		background-image: url(img/Valg-distrikt-mobil-2x.png);
	}
	.valg-banner-2015.nynorsk {
		background-image: url(img/Valg-distrikt-mobil-2x-NN.png);
	}
	.valg-banner-2015.sapmi {
		background-image: url(img/Valg-distrikt-mobil-2x-Samisk.png);
	}	
}

@media (-webkit-min-device-pixel-ratio: 2) and (min-width: 720px), (min-resolution: 192dpi) and (min-width: 720px) { 
	.col:not(.sl9) > .valg-banner-2015 {
		background-image: url(img/Valg-distrikt-desktop-2x.png);
	}
	.col:not(.sl9) > .valg-banner-2015.nynorsk {
		background-image: url(img/Valg-distrikt-desktop-2x-NN.png);
	}
	.col:not(.sl9) > .valg-banner-2015.sapmi {
		background-image: url(img/Valg-distrikt-desktop-2x-Samisk.png);
	}	
}