@require './mixins';

html.darkmode {
  body {
  	background-color: $darkmode-body-background;
    color: $darkmode-text;

    //  --- CORE CSS ---
    .nrk-color-base {
      background-color: $darkmode-body-background;
      color: $darkmode-text;
    }

    .nrk-color-shade-1 {
      color: $darkmode-text;
      background-color: $darkmode-body-background;
    }

    .nrk-button {
      background-color: transparent;
      &:hover,
      &:focus {
        {$darkmode-button-hover}
      }
    }

    // Custom styling for share-buttons
    .favorite-button-placeholder__sidebar button {
      background-color: $darkmode-background;
      color: $darkmode-text;
      span svg {
        color: $darkmode-text;
      }
      transition: transform .2s, box-shadow .2s
      &:hover {
        {$darkmode-button-hover}
      }
      &:after {
        content: none;
      }
    }

    //  --- COMPILATION ---
    .compilation-link a {
      color: $darkmode-text;
    }


    //  --- ELGLOBO ---
    .kur-room--xl {
      .kur-room__content {
        background-color: $darkmode-background;
      }
    }
    .kur-room__content {
      background-color: inherit;
    }

    .kur-room__title {
      color: inherit;
    }

    .kur-room--light {
      background-color: inherit;
      color: inherit;
    }

    //  --- LUDO ---
    .media-player-container {
      background-color: inherit;
    }

    .ludo {
      background-color: inherit;
      color: inherit;
      .ludo-playhead {
        background-color: $darkmode-text;
      }

      .ludo-progressbar {
        color: $darkmode-text;
      }

      .ludo-scrubber__time--pointer {
        background-color: $darkmode-background;
        color: $darkmode-text;
      }

      .ludo-bar__button {
        &:after {
          border-color: $darkmode-text;
        }
        &:hover {
          color: $darkmode-text;
          border-color: $darkmode-text;
          &:after {
            border-color: $darkmode-text;
          }
        }
      }

      .ludo-bar__button--volume {
        &:after {
          border-color: transparent;
          opacity: 0;
        }
        border-color: transparent;
        &:hover {
          border-color: transparent;
          &:after {
            opacity: 0;
            border-color: transparent;
          }
        }
      }

      .ludo-bar__volume__control {
        &:hover {
          color: $darkmode-text;
        }
      }

      .ludo-bar__volume__slider:focus + .ludo-bar__volume__slider-wrapper {
        color: $darkmode-text;
      }

      .ludo-bar__volume__slider:hover + .ludo-bar__volume__slider-wrapper .ludo-bar__volume__slider-overlay__thumb {
        background-color: $darkmode-text;
      }

      .ludo-bar__volume__slider-overlay__thumb {
        &:before {
          background-color: $darkmode-text;
        }
        background-color: $darkmode-text;
        &:hover {
          background-color: $darkmode-text;
        }
      }

      .ludo-bar__volume__slider-wrapper {
        background-color: rgba($darkmode-body-background, 0.6)
        &:before {
          border-color: $darkmode-text;
        }
      }
    }

    // --- PIN AVSTEMNING ---
    pin-avstemning-v1 {
      border-color: $darkmode-border-light;
      .pin-nrkno-poll__results-button {
        color: $darkmode-link;
      }
      button.pin-nrkno-poll__answer {
        background-color: $darkmode-background;
      }
    }

    /**
     *  --- NRKNO SPORTSOPPLEVELSE ---
     * Remove once this task is finished: https://nrknyemedier.atlassian.net/browse/NRKNO-6027
     */
    nrkno-sportsopplevelse-element.nrkno-sportsopplevelse {
      font-family: 'NRK Sans Variable'
      h1 {
        font-family: 'NRK Sans Variable';
      }
      color: $darkmode-text;
      div[class^="app__"] {
        color: $darkmode-text;
      }
      div[class^="tabs__wrapper__"] {
        border-color: $darkmode-border;
      }
      a[class^="tabs__pane"] {
        border-color: $darkmode-text-byline;
      }
    }
  }
}
