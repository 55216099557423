@require '../../common/css/mixins';

/* BASE STYLE */
body {
	font-nrk-sans();
	background-color: $color-white;
	font-size: 100%;
	height: 100%;
	margin: 0;
}

.absolute { position: absolute; }
.relative { position: relative; }
.fixed    { position: fixed; }

/* floats */
.fl    { float: left; }
.fr    { float: right; }
.clear { clear: both; }


/* inline block */
.inline-block {
	display: inline-block;
}

/* clearfix */
.cf:after { clear:both }
.cf:after, .cf:before {
	content: " ";
	display: block;
	padding: 0px !important;
	margin: 0px !important;
	height: 0px !important;
	visibility: hidden;
}

.js .init-size-zero {
	width: 0;
	height: 0;
}

body > ins {
	text-decoration: none;
	font-weight: 600;
	padding: 4px 8px;
	position: fixed;
	background: rgba(255, 50, 0, .6);
	text-align: center;
	font-size: 14px;
	color: white;
	left: 0;
	right: 0;
	top: 0;
	z-index: 999;
}

@media screen and (min-width: 720px) {
	.mobile-only {
		display: none;
	}
}

@media print {
  .nrkno-no-print {
  	display: none;
	}
}
