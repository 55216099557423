.universe-title {
  padding-right: 50px;
}
.universe-link {
	display: block;
	margin-top: -20px;
	margin-bottom: 30px;
	font-size: 12px;
	text-align: right;
	border-bottom: 1px solid #CCC;
	text-transform: uppercase;
	line-height: 30px;
	font-weight: bold;
	color: #26292A;
	position: relative;
}

.universe-link > .skin-plug-icon {
	display: inline-block;
	float: right;
	height: 15px;
	width: 26px;
	background-position: 100% 5px;
	margin: 5px -10px 0 0;
	padding-right: 11px;
}
.universe-title > .skin-plug-icon {
	background-position: 100% 2px;
	padding-right: 37px;
}

.surrogate > .universe-reference .universe-title > .skin-plug-icon {
	background-image: none;
}

.surrogate > .universe-reference .universe-title > .skin-text-hover:hover {
	color: inherit;
}

@media screen and (min-width:720px) {
	.surrogate > .universe-reference .universe-title > .skin-plug-icon {
		font-size: 40px;
		text-transform: none;
	}

	.surrogate > .universe-reference .universe-title {
		letter-spacing: normal;
		border-bottom: none;
		margin-bottom: 20px;
	}
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.universe-link > .skin-plug-icon {
		background-position: 9px -52px;
		padding-right: 0;
	}

	.universe-title > .skin-plug-icon {
		background-position: 100% -54px;
		padding-right: 21px;
	}
}
