@require '../../common/css/mixins';


// --- DARKMODE ---
html.darkmode {
  .video.brief, .video.lean {
    .video-label {
      background: $darkmode-background;
      color: $darkmode-text;

      .video-label-text {
        color: $darkmode-text;
      }
    }
    .video-label-icon {
      background: $darkmode-border;
      color: $darkmode-text;
    }
  }
}
// --- DARKMODE END

.video {
  clear: none;
  overflow: visible;
}

.media-player {
  position: relative;
  overflow: hidden;
  background: #000;
}

/*
 * PUB-11936
 * Poster that replicates the Ludo poster look, to avoid stylistic differences while
 * loading the Ludo container.
 */
.media-player-poster img {
  position: relative;
  display: block;
  width: 100%;
}

.media-player-poster__overlay {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, .1);
  background: -webkit-linear-gradient(top, transparent, transparent 30%, rgba(0, 0, 0, .2) 60%, rgba(0, 0, 0, .7));
  background: linear-gradient(180deg, transparent 0, transparent 30%, rgba(0, 0, 0, .2) 60%, rgba(0, 0, 0, .7));
}

.media-player-poster__no-video {
  background-color: rgba(0, 0, 0, .7);
  color: #fff;
  text-align: center;
  font-size: 1.2em;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.media-player-poster__no-video a {
  color: $nrk-color-core-blue-600;
}

.media-player-poster__no-video a:hover {
  border-bottom-color: $nrk-color-core-blue-600;
}

.video-hud {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, .65);
  color: white;
  z-index: 90;
  font-size: 14px;
  border: 1px solid #474444;
  border-bottom-width: 0;
  border-left-width: 0;
  padding: 13px 13px 13px 15px;
  box-sizing: border-box;
  z-index: 2;
}

.video-title {
  margin: 0;
  line-height: 1.3em;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
}

.video-metadata {
  padding: 0 13px 0 13px;
  margin-left: 60px;
  position: relative;
}

.video-metadata:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 1px solid #706d6d;
  min-height: 44px;
  z-index: 1;
}

.video-status {
  width: 44px;
  height: 44px;
  float: left;
  position: relative;
  background: transparent url(img/sprite.png) -352px 0;
}

.video-status.video-unloaded {
  background-position: -264px 0 !important
}

.sl9 .video-status {
  margin-left: -8 px;
  transform: scale(.6);
}

.video-message {
  font-size: 13px;
  padding: 5px 0 5px;
  display: block;
}

.video-message:empty {
  padding: 0;
}

.flow-content > .col > .widget.video.reference {
  padding-left: 30px;
  background: transparent url(img/reference-play.png) no-repeat left center;
}

.inline-video-placeholder .media-hud,
.inline-video-placeholder .video-hud {
  display: none
}

@media print {
  .media-player .video-hud {
    display: none
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .video-status {
    background-image: url(img/sprite-retina.png);
    background-size: 724px 44px;
  }
}

/* video brief */
.sl9 > .plug.video .title {
  position: static;
}

.sl9 > .plug.video .plug-image {
  width: 100%;
  float: none;
  margin-left: 0;
}
.video.brief, .video.lean {

  .plug-image {
    position: relative;
    margin-bottom: 0;
  }

  .plug-teaser {
    margin-top: 1em;
  }

  // Force 16:9 aspect ratio for all video teasers

  .plug-image .responsive-img {
    overflow: hidden;
    padding-bottom: 56.25%;
  }

  // Center image inside 16:9 aspect ratio box

  .plug-image .responsive-img img {
    height: auto;
    top: 50%;
    transform: translateY(-50%);
  }

  .video-duration {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba($nrk-color-core-blue-900, 0.8);
    padding: 0.25em 0.75em;
    font-size: 0.75em;
    font-weight: 600;
    line-height: 1.125;
    margin: 1em;
    color: white;
    border-radius: 1em;
  }

  .video-label {
    position: absolute;
    top: 100%;
    line-height: 1;
    background: white;
    display: inline-block;
    margin-left: 1rem;
    transform: translateY(-50%);
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
  }

  .video-label-icon {
    background: $nrk-color-core-blue-600;
    display: inline-block;
    color: #fff;
    width: 1.5rem;
    text-align: center;
    line-height: 1.5rem;
  }

  .video-label-icon .icon {
    vertical-align: middle;
    transform: translate(0, -1px);
  }

  .video-label-text {
    display: inline-block;
    line-height: 1.5rem;
    vertical-align: middle;
    font-size: 0.675em;
    font-weight: 500;
    padding: 0 0.5rem 0 0.325em;
    color: $nrk-color-core-blue-950;
  }

  .video-plug,
  .plug-metadata {
    margin-top: 8px;
  }

  .plug-metadata {
    visibility: hidden;
  }

  figcaption {
    display: none;
  }
}

.no-js .video.brief .plug-metadata {
  visibility: visible;
}

.plug-svg-arrow {
  display: none;
}

@media screen and (min-width: 720px) and (max-width: 1024px) {
  .sl9 > .video .video-metadata {
    margin-left: 40px;
    padding-right 0
  }

  .sl9 > .video .video-metadata:before {
    min-height

    28px
  }

  .sl9 > .video .video-title {
    font-size: 13px
  }

}

/* Overwrites: Standalone video
------------------------------------------------------------------*/

.surrogate-content.video.surrogate-content.video {
  margin: -10px 0 0 0;
}

.surrogate-content.video .video-info {
  position: relative;
  padding: 15px;
  margin-bottom: 32px;
}

.surrogate-content.video .video-title {
  font-weight: 700;
  font-size: 26px;
  line-height: 1.2;
  margin: 0 0 5px 0;
}

.surrogate-content.video .video-description {
  margin: 0 0 24px 0;
  font-size: 16px;
  line-height: 1.5;
}
.surrogate-content.video .video-footer {
  border-top: 1px solid #e9e9e9;
  padding-top: 5px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.surrogate-content.video .video-date {
  line-height: 1.5em;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #666669;
  margin: 5px 0;
}

.surrogate-content.video .video-share {
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.video-share__toggle {
  background: transparent;
  padding: 5px 0;
  margin: 0;
  border: 0;

  &:hover,
  &:focus {
    box-shadow: none;
  }
  svg {
    font-size: 15px;
    margin-right: 5px;
    vertical-align: middle;
  }
  span {
    font-size: 15px;
    color: #0059b3;
  }
}
.video-share__dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
  background: #fff;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: 0;
}
.video-share__dropdown-item {
  list-style: none;
  min-width: 240px;

  a {
    padding: 0 15px;
    height: 40px;
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
      background-color #e9e9e9
    }
    &:active {
      transform: translateY(1px);
    }
  }
  span {
    color: #0059b3;
  }

  svg {
    font-size: 10px
    margin-right: 10px;
  }
}
.video-share__embed-title {
  font-size: 13px;
  text-transform: uppercase;
  color: #26292a;
  font-weight: normal;
  margin: 24px 15px 5px 15px;
}
.video-share__embed-code {
  background-color: #e9e9e9;
  padding: 15px;
  margin: 0;
  border: 0;
  box-sizing: border-box;
  font-size 13px
  display: block;
  resize: none;
  width: 100%
  height 100px
}

// Double selector to increase spesificity
.video-share__copy-to-clipboard--copied
.video-share__copy-to-clipboard--copied {
  color: #008c0b;
  span {
    display: none;
  }

  &::after {
    content: 'Kopiert!'
  }
}

@media screen and (min-width: 720px) {
  .surrogate-content.video.surrogate-content.video {
    margin: -10px 15px 0 15px;
  }
  .surrogate-content.video .video-info {
    padding: 15px 0;
    max-width: 640px;
  }
  .surrogate-content.video .video-title {
    font-size: 32px;
  }
}

@media screen and (min-width: 1024px) {
  .surrogate-content.video .video-title {
    margin-top: 0;
  }
}

/* Overwrites: Embedded
------------------------------------------------------------------*/
.embedded .surrogate-content .video-embed,
.embedded .surrogate-content .video-share,
.embedded .surrogate-content .video-info{
  display: none
}

.embedded .surrogate > .col > .widget.video {
  padding: 0;
}

.embedded .surrogate > .col > .widget .media-player-container {
  width: auto;
  margin: 0;
  float: none;
}


.article.rich .ludo--1x1,
.video-page .ludo--1x1,
.article.rich .ludo--9x16,
.video-page .ludo--9x16 {
  max-height: 80vh;
}
.article.rich .ludo--1x1 .ludo-poster__image,
.video-page .ludo--1x1 .ludo-poster__image,
.article.rich .ludo--9x16 .ludo-poster__image,
.video-page .ludo--9x16 .ludo-poster__image {
  width: auto;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}

