/* Album open
----------------------------------------------------------------*/
.album figcaption small {
  display: none;
}

.album--rich { display: block; position: relative }

.album--rich button {
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  border: 0;
  padding: calc(30% - 70px);
  color: #fff;
  background: transparent;
  font-weight: 700;
  font-size: 22px;
  line-height 1.2;
  text-shadow: 0 1px 1px #000;
  text-align: center;
  transition: .2s;

  .album-button-content {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    padding-top: 20%;
  }
}

.album--rich figure .responsive-img::before {
  content: '';
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  background: linear-gradient(transparent, rgba(0,0,0,.6));
  opacity: 1;
  transition: opacity .4s,
}
.album--rich:hover figure .responsive-img::before { opacity: 0 }
.album--rich button > .album-button-content:before {
  display: block;
  margin: 0 auto;
  content: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20100%20100%22%20width%3D%2266%22%20height%3D%2266%22%3E%3Ccircle%20fill%3D%22rgba(0%2C0%2C0%2C.4)%22%20stroke%3D%22%23fff%22%20stroke-width%3D%222%22%20cx%3D%2250%22%20cy%3D%2250%22%20r%3D%2248%22%2F%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M28%2035v37h50V35H28zm6.7%2032L41%2046.6l7.2%2016%207.4-8%209%2012.4h-30zm29-15.7c-1.8%200-3.3-1.4-3.3-3.2%200-1.7%201.5-3%203.3-3%201.8%200%203.3%201.3%203.3%203%200%201.8-1.5%203.3-3.3%203.3zM19%2028h48v3H19zm0%200h3v36h-3z%22%2F%3E%3C%2Fsvg%3E')
  height: 66px;
  width: 66px;
}

.album--rich noscript figure { position: relative; z-index: 3; background: #fff } /* Hide button when no js */
.album--rich small { display: block; font-size: 14px }


.album__description {
  display: none;
}

/* Album dialog
----------------------------------------------------------------*/
html.dialog-html-no-scroll {
  overflow: hidden;
}

.dialog-body-no-scroll {
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
}

.album-dialog {
  box-sizing: border-box;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  border: 0;
  padding: 4%;
  right: 0;
  bottom:0;
  background-color: #141517;

  figcaption {
    small {
      letter-spacing: 0.015em;
    }
    p + small {
      margin-top: 7px;
    }
  }

  .album-dialog__heading {
    position: fixed;
    top: 0;
    left: 0;
    right:0;
    height: 0;
    z-index: 11;
    .nrk-logo {
      background-color: transparent;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 49 18'%3E%3Cpath fill='%23fff' d='M42.5 0h6.7L44 8.4a1.3 1.3 0 0 0 0 1.5l5.3 8h-6.7l-4.3-7.7a2.4 2.4 0 0 1 0-2.8zM6.4 0h4.3a3 3 0 0 1 3 2.8l3 15.2h-6.4zM0 0h6v18H0zM17 0h6v18h-6zM31 0h6v18h-6zM30.4 3.4c0-4.7-7-4.4-6.8.3a3.3 3.3 0 0 0 3.6 3 3.2 3.2 0 0 0 3.2-3.3z'/%3E%3C/svg%3E") 50%/58px no-repeat;
      width: 110px;
      box-sizing: border-box;
      display: block;
      overflow: hidden;
      vertical-align: top; /* For icons */
      height: 55px; /* To make visible for screen readers */
      border: 0;
      color inherit;
      opacity: 1;
      text-decoration: none;
      text-overflow: ellipsis;
      transition: opacity .2s, background-color .2s;
      -webkit-transform: translateZ(0); /* Fix opacity+font in Safari */
    }
  }

}

.album-dialog__title {
  margin: 0;
  position: relative;
  padding: 28vh 16px;
  .album-dialog__title-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100% auto;
    background-repeat: no-repeat;
    z-index: 1;
    filter: blur(15px);
    -webkit-filter: blur(15px);
    -moz-filter: blur(15px);
    -o-filter: blur(15px);
    -ms-filter: blur(15px);
    filter: s("progid: DXImageTransform.Microsoft.Blur(PixelRadius = '15')");
    &::after {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      max-height: 57vw;
      background: linear-gradient(to bottom, rgba(20, 21, 23, 0.4), rgba(20, 21, 23, 0.4) 7%, rgba(20, 21, 23, 1) 100%);
      filter: s('progid:DXImageTransform.Microsoft.gradient( startColorstr="#66202123", endColorstr="#FF202123",GradientType=0 )');
      content: '';
    }
  }
  h2, p {
    position: relative;
    text-align: center;
    margin: 0 auto;
    z-index: 3;
  }
  h2 {
    font-weight: 400;
    max-width: 600px;
    padding-bottom: 0.83em;

  }
  p {
    max-width: 550px;
  }
}

.album-dialog__content {
  background: inherit;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate(0);
}

.album-dialog__items {
  padding: 0;
}

.album-dialog__item {
  margin-bottom: calc(9vh + 50px);
  opacity: 1;
  > .wrapper {
    margin: 0 auto;
  }
}
.album-dialog__item > * { width: 100% }
.album-dialog__item {
  .image {
    max-width: 125vh;
    margin: 0 auto;
    overflow: visible;  // because of focus outline on share toggle button.
    &.aspect-portrait {
      max-width: 60vh;
    }
    &.aspect-square {
      max-width: 75vh;
    }
    figcaption {
      overflow: visible;  // because of focus outline on share toggle button.
      position: relative;
      color: #c8c8c8;
      min-height: 47px;
      p {
        margin-right: 30px;
      }
      p, button {
        font-size: 15px;
      }
    }
  }
}

.album-dialog__close { position: fixed; z-index: 9; top: 0; right: 0; margin: 6px; border: none; }
.album-dialog__close:hover {border: none; box-shadow: none}


.album-dialog {
  .album-dialog__close {
    position: fixed;
    z-index: 9;
    top: 0;
    right: 0;
    margin: 6px;
    border: none;
    background: transparent;
    color: rgba(255,255,255,1);
    width: 35px;
    min-width:35px;
    height: 35px;
    &:hover {
      border: none;
      box-shadow: none;
    }
    &:focus {
      color: #00B9F2;
    }
  }
}


.album-dialog__share {
  text-align: center;
  padding-bottom: 20px;
}

@media screen and (max-width: 719px) {
  .album-dialog {
    &:before {
      content: '';
      pointer-events: none;
      position: fixed;
      z-index: 10;
      top: 0;
      left: 0;
      right: 0;
      height: 8%;
      background: linear-gradient(to bottom, rgba(21,23,0, 0.8), rgba(0,0,0,0.0));

    }
    &:after {
      content: '';
      pointer-events: none;
      position:fixed;
      z-index: 10;
      bottom: 0;
      left: 0;
      right: 0;
      height: 8%;
      background: linear-gradient(to top, rgba(21,23,0, 0.8), rgba(0,0,0,0.0));
    }
  }
  .album-dialog__title {
    h2 {
      font-size: 27px;
    }
    p {
      font-size: 19px;
    }
  }
  .album-dialog__item {
    .image {
      figcaption {
        p, small {
          margin-left: 6px;
        }
      }
    }
  }
}

@media screen and (min-width: 720px){
  .album-dialog__title {
    h2 {
      font-size: 38px;
      max-width: 900px;
      padding: 0 30px 0.83em;
    }

    p {
      font-size: 22px;
      line-height: 35px;
      text-shadow: 0.1px 2px rgba(0,0,0,0.5);
      max-width:600px;
      margin: 0 auto;
    }
  }

  .album-dialog__items {
    padding: 0 6px;
  }

  .album-dialog__item {
    opacity: .22;
    transition: opacity 1s;
    will-change: opacity;
  }

  .album-dialog__title {
    font-size: 30px;
    padding: 29vh 5px 29vh;
  }
  .album-dialog__items {
    padding: 0 calc(12vw - 84px);

  }
  .album-dialog {
    .album-dialog__close {
      width: 50px;
      height: 50px;
      margin-right: 22px;
    }
  }

}
