@require "../../common/css/mixins";

// --- DARKMODE ---
html.darkmode {
  .group.floor {
    background: $nrk-color-core-blue-900;
  }
}
// --- DARKMODE END ---

.group.floor {
	overflow: hidden;
	background: $nrk-color-core-blue-50;
	margin-bottom: 20px !important;
}

.group.floor.floor-top {
	margin-top: 0 !important;
}

.group.floor.floor-bottom {
	margin-bottom: 0 !important;
}

.group.floor.negative {
	background: $nrk-color-core-blue-950;
	-webkit-font-smoothing: auto;
}

.group.floor-top {
	overflow: visible;
	background: transparent;
	margin-bottom: 0 !important;
}

.group.floor-content {
	padding-top: 0 !important;
}

@media screen and (min-width: 720px) {
	.group.floor {
		margin-bottom: 20px !important;
		margin-top: 20px !important;
	}

	/* revert grid definition overrides on mobile */
	.group.floor-top {
		overflow: hidden;
		background: $nrk-color-core-blue-50;
		margin-bottom: 20px !important;
	}

	.group.floor-content {
		padding-top: 20px !important;
	}
}
