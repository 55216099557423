@require '../../common/css/mixins';

.table > figure {
	font-size: .85em;
	line-height: 1.45;
}
.table h2 {
	font-size: 18px;
	margin-top: 0;
	margin-bottom: 10px;
}
.table table {
	width: 100%;
	border-spacing: 0;
	border-collapse: separate;
}
.table table p {
	margin: 0;
	padding: 8px;
	text-align: left;
}
.table thead p {
	font-weight: 600;
}

.table tbody td:first-child {
	border-left-width: 0;
}
.table tbody td {
  border-width: 0
	border-left-width: 1px;
  border-style: solid;
  border-color: inherit;
}
.table tbody tr + tr td {
	border-top-width: 0;
}

// add color to links in text blocks
.table a[href] {
	color: $nrk-color-core-blue-600;
	border-bottom: 1px solid $nrk-color-gray-300;
	padding-bottom: 2px; // descending letters (g,j,p,q,y) touches the border without this padding.
}

.darkmode .table a[href] {
  color: $darkmode-link;
}

.table a[href]:hover {
	border-bottom: 1px solid $text-color-anchor;
}

.table__expandable > figure > div {
  max-height: 300px;
  transition: max-height .5s;
  overflow: hidden;
}

.table__expand div {
  text-align: center;
  display: block;
  border-radius: 100px;
  margin: 5px auto 0;
  height: 40px;
  width: 120px;
  position: relative;
}

.table__expand div::before {
  width: 100%;
  height: 100%;
  content: 'Vis mer';
  font-weight: 640;
  line-height: 40px;
}
.table--expanded .table__expand div::before {
  content: 'Vis mindre';
}
