.sandbox-responsive {
	position: relative;
	height: 0;
	overflow: hidden;
}
.sandbox-responsive > iframe{
	border: 0;
}
.sandbox-responsive > iframe,
.sandbox-responsive > .alert {
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	line-height: 1.6;
	overflow: hidden;
}

@media $mq-medium {
	.sandbox-responsive > .alert p {
		font-size: 16px;
		line-height: 1.8;
	}
}
