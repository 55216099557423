.mgp-header-wrapper {
	background: black url(img/mgp-background.jpg) repeat-x center center;
}

.mgp-header {
	height: 60px;
	overflow: hidden;
}

.mgp-header img {
	max-height: 100%;
	padding: 0 10px 0 10px;
	margin-left: -124px;
}

@media screen and (min-width:720px) { 
	.mgp-header {
		height: 90px;
	}

	.mgp-header img {
		margin-left: -30px;
	}

}