@require '../../common/css/mixins';

// --- DARKMODE ---
html.darkmode {
  .review-tag {
    background-color: $darkmode-background;
    color: $darkmode-text;
  }
  .review-summary {
    .review-info {
      p.secondary {
        color: $darkmode-text-byline;
      }
    }
  }
}
// --- DARKMODE END ---

// Need to hide lead picture captions in reviews
.review figure figcaption {
  display: none;
}

.review-summary {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .review-tag {
    color: $text-color;
    background-color: $color-white;
    display: flex;
    align-items: center;
    white-space: nowrap;
    z-index: 3;
    position: relative;
    transform: translate(0, -50%);

    .review-rating {
      color: $color-white;
      background-color: $color-dice;
      display: inline-block;
      min-width: 5px;
      font-size: 18px;
      min-height: 28px;

      svg {
        display: block;
      }
    }

    .review-type {
      padding: 0 8px;
      font-size: 13px;
    }
  }

  h3 {
    font-size: 18px;
    margin: 0;
  }

  .review-info {
    margin-top: -5px;
    p {
      margin: 2px 0;

      &.secondary {
        color: #666669;
      }
    }

    div {
      margin-bottom: 15px;
    }
  }
}

.plug .review-summary {
  margin-top: -8px;
  padding-bottom: 16px;
}

.plug .review-tag,
.plug-feature .review-tag {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 720px) {
  .sl9 > .plug .review-summary {
    align-items: flex-start;
    margin-top: 0;
    padding-bottom: 8px;
  }
}
@media screen and (max-width: 720px) {
  .sl9 > .plug:not(.plug-feature) .review-tag {
    position: static;
    transform: none;
  }
}

@media screen and (max-width: 719px) {
  .article.rich .review .review-summary {
    border-bottom: 1px solid #e0e0e0;
  }
}
