.grid {
  // overrides for compilation (compilation-rich.jsp)
  .nrkno-pin-teaser {
    order: 3;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
  }
}
// nrkno-serum-render
.nrkno-pin-teaser {
  // overrides
  .bulletin-circle {
    display: none !important;
  }

  margin-top: 10px;
  margin-bottom: 30px;
  border-radius: 3px;

  &__error-message {
    font-size: 14px;
  }

  &--hidden {
    display: none;
  }

  &--initializing {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }

  &--initialized {
    border-radius: 3px;
    > .teaser {
      animation: 0.5s fadein;
      // override
      padding: 14px 10px 10px;
    }
  }

  &__delay-show {
    animation: 0.25s fadein backwards;
    animation-delay: 1s;
  }

  &__spinner {
    animation: 1s infinite linear org-spinner;
    pointer-events: none;
    @keyframes org-spinner {
      to {
        transform: translateZ(0) rotate(360deg);
      }
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
