@require '../../common/css/mixins';

// --- DARKMODE ---
html.darkmode {
  .widget figcaption {
    color: $darkmode-text;
    small {
      color: $darkmode-text-byline;
    }
  }

  .article-body {
    .plug {
      border: none;
    }
    .plug-default {
      background-color: $darkmode-background;
    }
  }

  .article-universe-teaser,
  .article-footer {
    border-color: $darkmode-border;
  }

  .article-universe-link {
    color: $darkmode-link;
    span {
      color: $darkmode-text;
    }
  }

  .article-source,
  .article-location {
    color: $darkmode-text;
  }

  .article-source em,
  .article-location em {
    color: $darkmode-text-byline;
  }

  h2.numbered-heading {
    color: $darkmode-text;
    &:before {
      background-color: $nrk-color-core-blue-700;
    }
  }

  .article-dateline {
    color: $darkmode-text;
    .article-update-date {
      border-color: $darkmode-border-light;
    }
  }

  .article-feature__body > h2 {
    color: $darkmode-text;
  }

  .note-container {
    .note-button {
      color: $darkmode-text;
      border-color: $darkmode-text;
      &:focus {
        outline: 2px dotted $darkmode-text;
        border: none;
      }
    }
    .note-button[aria-expanded="true"] {
      background: $darkmode-text;
      color: $darkmode-body-background;
      outline: none;
    }
    .note-content {
      background: $nrk-color-core-blue-900;
      color: $nrk-color-core-blue-50;
      .note-close {
        color: $nrk-color-core-blue-50;
      }
    }
  }

  .article-oldness {
    background: $darkmode-background;
    border-color: $darkmode-border;
    color: $darkmode-text;
  }

  @media screen and (max-width: 719px) {
    .article-lead-media > .image figcaption:not(.caption-expanded) p:first-child:before,
    .article-body > .video-reference figcaption:not(.caption-expanded) p:first-child:before,
    .article-body > .col.image-reference figcaption:not(.caption-expanded) p:first-child:before {
      color: $darkmode-text;
    }

    .article-body > .col.fr.image-reference > .image,
    .article-body > .col.fl.image-reference > .image {
      background: none;
    }

    .article-body > .image-reference figcaption,
    .article-lead-media > .image figcaption {
      border-bottom: 1px solid $darkmode-border;
    }

    .article .favorite-button-placeholder__metadata.favorite-button-placeholder__metadata button {
      * {
        color: $darkmode-text;
      }
      &:after {
        border-color: $darkmode-border;
        opacity: 1;
      }
    }

  }
}
// --- DARKMODE END ---

/* Favorite-button */
.article .favorite-button-placeholder__metadata.favorite-button-placeholder__metadata { /* Increasing specificity to override styling from Min Side */
  button {
    background: none;
  }
}

/* End of favorite-button */

// Add spacing above title on article-pages
#container > .group-reference.sAUTO > .group > .surrogate-reference > .surrogate > .article-reference > .article {
  padding-top: 10px;
}
@media screen and (min-width: 720px) {
  #container > .group-reference.sAUTO > .group > .surrogate-reference > .surrogate > .article-reference > .article {
    padding-top: 30px;
  }
}

.article-oldness {
  font-size: 13px;
  width: 150px;
  background: $nrk-color-core-blue-50;
  border: 1px solid $nrk-color-core-blue-100;
  color: $nrk-color-core-blue-900;
  text-align: center;
  p {
    &::before {
      display: block;
      content: "!";
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border: 1px solid;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 13px;
    }
  }
}

.title-large {
  letter-spacing: -0.015em;
  margin-bottom: 10px;
}

.title-large:not(.review) {
  font-variation-settings: "wdth" 90;
  font-weight: 740;
}

.article-lead {
  margin-bottom: 1.5em;
  color: #061629;

}
.article-lead > p {
  font-size: 19px;
  font-weight: 520;
  line-height: 1.4;

  @media screen and (min-width: 720px) {
    font-size: 24px;
  }
}

.article-body p > strong {
  font-weight: 640;
}

.article-dateline__short {
  display: none;
}
.article-dateline {
  font-size: 13px;
  font-weight: 400;
  color: #6c6c6c;
  line-height: 1.2em;
  .article-publish-date, .article-update-date {
    margin-bottom: 2px;
  }
}
.article-metadata--mobile {
  display: none;
}

.article-feature li h2,
.article-body li h2 {
  font-size: inherit;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
}

.article-body--updating {
  font-style: italic;
}

p:empty.article-body--updating {
  display: none;
}

.article-type {
  display: block;
  width: 15px;
  border-bottom-style: solid;
  border-bottom-width: 7px;
  font-size: 14px;
  font-weight: 200;
  text-transform: uppercase;
  white-space: nowrap;
}

@media screen and (min-width: 760px){
  .article-type {
    margin-top: 10px;
  }
}

/* small screen styling */
@media screen and (max-width: 719px) {

  .article-type {
    margin: 10px 0 15px 0;
  }

  .article-dateline__full {
    display: none;
  }

  .article-dateline__short {
    display: inline;
  }

  .article-header-sidebar .article-metadata--mobile {
    display: block;
    height: 41px;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    margin: 0 -10px;


  }
  .article-header-sidebar .article-metadata--mobile .article-dateline {
    display: block;
    height: 21px;
    font-size: calc(9px + 1rem / 4);
    overflow: hidden;
    box-sizing: content-box;
  }

  .article-dateline:not(.article-footer) {
    display: none;
    line-height: 1.85em;
  }

  .article-oldness {
    width: 100%;
    p {
      position: relative;
      text-align: left;
      padding-left: 45px;
      &::before {
        position: absolute;
        left: 11px;
        top: -2px;
        text-align: center;
        margin-bottom: 0px;
      }
    }
  }

  .article-lead-media {
    margin-bottom: 20px !important;
  }
  .article-lead-media > .widget {
    margin-bottom: 0 !important;
  }

  .article-lead-media.col.fr:before,
  .article-lead-media.col.fl:before {
    content: '';
  }

  .article-lead-media > .image figcaption {
    border-bottom: 1px solid #e0e0e0;
  }

  /* article media caption is initially collapsed */
  .article-lead-media > .image figcaption:not(.caption-expanded) * {
    display: none;
  }

  .article-lead-media > .image figcaption:not(.caption-expanded) small:first-child {
    display: block;
  }

  .article-lead-media > .image figcaption:not(.caption-expanded) p:first-child {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    height: 1.4em;
  }

  .article-lead-media > .image figcaption:not(.caption-expanded) p:first-child:before {
    content: 'Vis mer' / '';
    text-align: right;
    float: right;
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 2px;
    color: #0059b3;
    margin-left: 15px;
  }

  /* Hide video captions on videos inside articles  */
  .article-body > .video-reference figcaption:not(.caption-expanded) * {
    display: none;
  }

  .article-body > .video-reference figcaption:not(.caption-expanded) small:first-child {
    display: block;
  }

  .article-body > .video-reference figcaption:not(.caption-expanded) p:first-child {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    height: 1.4em;
  }

  .article-body > .video-reference figcaption:not(.caption-expanded) p:first-child:before {
    content: 'Vis mer';
    text-align: right;
    float: right;
    color: #0059b3;
    margin-left: 15px;
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  /* article media bleeds on small screens */
  .article-lead-media {
    margin-bottom: 5px !important;
  }
  .article-lead-media > .widget {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
  }

  /* ...except captions */
  .article-lead-media figcaption > p,
  .article-lead-media figcaption > small {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  /* spacing below article image */
  .article-no-byline .article-lead-media {
    margin-bottom: -1px !important;
  }
  .article-no-byline .article-lead-media > .widget {
    margin-bottom: 0 !important;
  }

  /* articles source without without byline needs space */
  .article-header-sidebar > div:first-child > .article-source {
    margin-top: 12px;
  }

  .article-header-sidebar .authors {
    padding-top: 5px;
    line-height: 1;
  }

  .article-dateline {
    display: block;
    padding: 10px 10px 10px 10px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    .publish-date-with-update-date {
      display: none;
      & + .update-date {
        display: block;
        &::before {
          content:'';
        }
        .article-dateline-annotation {
          text-transform: capitalize;
        }
      }
    }
    &.article-footer {
      .publish-date-with-update-date {
        display: block;
        .article-dateline-annotation {
          display: inline;
        }
      }
    }
  }


  .article-dateline:not(.article-footer) {
    display: none;
    line-height: 1.85em;
  }

  .publish-date-with-update-date {
    .article-dateline-annotation {
      display: none;
    }
  }
  .article-update-date {
    &::before {
      content: '–'
    }
    text-transform: lowercase;
    letter-spacing: 0;
  }


  .article-footer {
    margin-bottom: 10px;
    overflow: hidden;


  }

  .article-update-date {
    margin-right: 10px;
  }

  .article-universe-list {
    margin: 0;
    padding: 0;
  }

  /* hide article universe list in header */
  .article-universe-list,
  .article-universe-teaser,
  .article-universes {
    display: none;
  }

  .article-title {
    margin-top: 5px;
  }

  .article .expanded-figure-transition {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    -ms-transition: none;
    transition: none;
  }

  .article .article-body > .col.fr.image-reference,
  .article .article-body > .col.fl.image-reference {
    width: 100%;
  }

  .article .article-body > .col.fr.image-reference:before,
  .article .article-body > .col.fl.image-reference:before {
    content: '';
  }

  .article-body > .col.fr.image-reference > .image,
  .article-body > .col.fl.image-reference > .image {
    background: -webkit-linear-gradient(top,  rgba(243,243,243,1) 0%, rgba(255,255,255,0) 100%);
    background: -ms-linear-gradient(top,  rgba(243,243,243,1) 0%, rgba(255,255,255,0) 100%);
    background: linear-gradient(to bottom,  rgba(243,243,243,1) 0%, rgba(255,255,255,0) 100%);
  }

  .article-body > .image-reference figcaption {
    border-bottom: 1px solid #e0e0e0;
  }

  /* caption-expanded reverts to "normal" caption styling */
  .article-body > .image-reference figcaption:not(.caption-expanded) * {
    display: none;
  }

  .article-body > .image-reference figcaption:not(.caption-expanded) small:first-child {
    display: block;
  }


  .article-body > .image-reference figcaption:not(.caption-expanded) p:first-child {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    height: 1.4em;
  }

  .article-body > .col.image-reference figcaption:not(.caption-expanded) p:first-child {
    &:before {
      content: 'Vis mer';
      text-align: right;
      float: right;
      color: #0059b3;
      margin-left: 15px;
      font-weight: bold;
      text-decoration: underline;
      text-underline-offset: 2px;
    }

    &.no-credit.one-line:before, &.hide-pseudo:before {
      display: none;
    }
  }

  .article-body > .col.fr.image-reference .F34,
  .article-body > .col.fl.image-reference .F34 {
    padding-bottom: 100%;
    max-width: 75%;
    margin: 0 auto;
  }

}

/* dateline */
.article-dateline {
  font-size: 13px;
  font-weight: 500;
  color: rgba(38, 41, 42, 0.8);
  line-height: 1.2em;
}

/* keep publish/update dates on separate lines */
.article-update-date,
.article-publish-date {
  display: inline-block;
}

.article-footer {
  padding-top: 1em;
  border-top: 1px solid #e0e0e0;
}

.article-comments-message {
  color: rgba(38, 41, 42, 0.8);
  font-size: 15px;
  font-weight: 700;
  line-height: 1.652em;
  margin-bottom: 2em;
}

.article-comments-load ~ .article-comments-message {
  display: none;
}

.article-comments-load svg {
  vertical-align: -3px;
  width: 15px;
  height: 15px;
  opacity: .2;
}

/* universe links */
.article-universe-link {
  position: relative;
  margin-bottom: 5px;
  font-size: 14px;
  color: #0059b3;
  line-height: 1.4em;
  display: inline-block;
  padding-right: 26px;
  background-size: 20px 13px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 15'%3E%3Cpath stroke='%236c6c6c' fill='none' stroke-linecap='round' d='M2 7.5h22m-6-6l6 6-6 6'/%3E%3C/svg%3E");
  background-position: right;
  background-repeat: no-repeat;
}


.article-universe-link.universe,
.article-universe-link > .universe {
  font-weight: normal;
}

.article-universe-link > span {
  color: #6c6c6c;
  font-weight: normal;
}

/* images */
.expanded-figure-container {
  width: 100% !important;
  max-width: 100% !important;
}

.expanded-figure-container .expanded-figure {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.expanded-figure-transition {
  transition: width .4s, margin .4s, max-width .4s;
}

.article-body > .col.fr.image-reference,
.article-body > .col.fl.image-reference {
  cursor: pointer;
  position: relative;
}

/* image expansion icon */
.article-body > .col.fr.image-reference:before,
.article-body > .col.fl.image-reference:before {
  content: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%3Ccircle%20fill-opacity%3D%22.3%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%229.4%22%20fill%3D%22%23fff%22%2F%3E%3Cpath%20fill%3D%22%23333%22%20d%3D%22M9.4%206h1.3v8H9.4z%20M6%209.4h8v1.3H6z%22%2F%3E%3C%2Fsvg%3E');
  pointer-events: none;
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  transition: .2s;
}

.article-body > .col.fr.expanded-figure-container.image-reference:before,
.article-body > .col.fl.expanded-figure-container.image-reference:before {
  transform: rotate(45deg);
}

/* reset left and right margin for plug inside section inside article-body */
.article-body > .col > .widget > .col > .plug {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 15px !important;
}

/* styling for plug inside article-body */
.article-body .plug {
  border: 1px solid #e0e0e0;
}
.article-body .plug .plug-image {
  margin-left: -1px;
  margin-right: -1px;
}
.article-body .plug-default .plug-teaser {
  padding: 16px;
}


/* article source field */
.article-source,
.article-location {
  margin-bottom: 0;
  color: rgba(38, 41, 42, 0.8);
  font-size: 13px;
}
.article-source + .article-location {
  margin-top: 4px;
}

.article-source em,
.article-location em {
  font-weight: 600;
  font-style: normal;
  color: #6c6c6c;
}

/* android/IE 9 hack */
.article .icon > svg {
  height: 12px;
  width: 12px;
}

.article .nrk-button[data-comments-load] { min-width: 215px }
.article .nrk-button[data-comments-load] svg {
  margin-top: -3px;
  height: 15px;
  width: 15px;
}

.plug-svg-arrow {
  display: none;
}

@media screen and (min-width: 720px) and (max-width: 900px) {

  /* increase sidebar size on small-ish screens */
  .article-header-sidebar {
    width: 27.7777777778% !important;
  }

  .article-sidebar {
    width: 33.333333333% !important;
  }
}

/* large screen sizes */
@media screen and (min-width: 720px) {

  #container > .sAUTO > .container-widget > .col > .article {
    margin-top: 30px !important;
  }

  .article-header {
    margin-right: 35px !important;
  }

  .article-type {
    font-size: 17px;
    width: 20px;
  }


  /* normalized margins */
  .article:not(.reference) {
    margin-bottom: 30px !important;
  }

  .s18 > .article-no-lead-media .article-header-sidebar {
    position: relative;
    left: -5.55555555556%;
    margin-bottom: 2em;
    border-left: 1px solid #e0e0e0;
  }
  /* spacing between header and article body */
  .s12.article-lead-media {
    margin-bottom: 30px;
  }
  .s12.article-lead-media > .widget {
    margin-bottom: 0 !important;
  }

  /* small article universe list */
  .article-universe-list {
    display: block;
    padding: 0;
    margin: 0;
    list-style-type: none;
    margin-top: 10px;
  }

  .article-universe-list li {
    position: relative;
    margin-bottom: 4px;
  }

  /* limit body text width */
  .s10 > .article-body,
  .s10 > .article-comments,
  .s10 > .article-footer {
    max-width: 500px;
  }

  /* to maintain overall layout for small amounts of text, set min-height */
  .s10 > .article-body {
    min-height: 250px;
  }

  /* limit lead text width */
  .s17 > .container-widget-content > .article-lead {
    max-width: 680px;
  }

  /* offset sidebar slightly to align with body text */
  .article-sidebar {
    padding-top: 6px;
  }

  /* limit sidebar item width */
  .article-sidebar > .widget,
  .article-sidebar .container-widget-content,
  .article-sidebar .col > .widget,
  .article-header-sidebar > .container-widget-content {
    max-width: 228px;
  }

  /* sidebar element spacing */
  .article-header-sidebar > .container-widget-content {
    margin-bottom: 20px;
  }

  /* plug overrides (see plug.css) */
  .article-sidebar .plug-teaser > .title {
    font-size: 16px;
  }

  .article-sidebar .plug-footer,
  .article-sidebar > .universe > .container-widget-content {
    display: none;
  }

  /* ...except for wrapper widgets */
  .article-sidebar > .widget.container-widget {
    max-width: none;
  }

  /* teaser text below article universe */
  .article-sidebar > .article-universe-teaser {
    padding-bottom: 5px;
    border-bottom: 1px solid #e0e0e0;
    margin-top: -10px;;
}

/* spacing below article unverse */
.article-sidebar > .article-universe-teaser + .universe {
  margin-top: 50px !important;
}

.article-header-sidebar .authors {
  margin-bottom: 50px;
}

.article-header-sidebar .author__image {
  display: block;
  margin-bottom: 4px;
}

/* dateline */
.article-dateline {
  margin-top: 20px;
}

/* border between publish/update date */
.article-footer .article-update-date {
  border-left: 1px solid #e0e0e0;
  padding-left: 10px;
  margin-left: 10px;
}

/* comments */
.article-comments {
  margin-top: 30px;
}

}

@media screen and (min-width: 1000px) {

  .article-body > .col.fr.image-reference,
  .article-body > .col.fl.image-reference
  {
    max-width: 230px;
  }

  .expanded-figure-transition {
    -webkit-transition: width .4s, margin .4s, max-width .4s;
    -moz-transition: width .4s, margin .4s, max-width .4s;
    -o-transition: width .4s, margin .4s, max-width .4s;
    -ms-transition: width .4s, margin .4s, max-width .4s;
    transition: width .4s, margin .4s, max-width .4s;
  }

  /* spacing between header and article body */
  /* ... except when there's a review box */
  .s12.article-lead-media:not(.review) {
    margin-bottom: 60px;
  }
  .s12.article-lead-media > .widget {
    margin-bottom: 0 !important;
  }

  /* offset article universe lists on largish screens */
  .s5.article-sidebar > .universe:first-child {
    margin-top: 180px !important;
  }

  /* ...except when there is no lead media */
  .article-no-lead-media .s5.article-sidebar > .universe:first-child {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 1100px) {

  /* limit body text width */
  .s10 > .article-body,
  .s10 > .article-comments,
  .s10 > .article-footer {
    max-width: 540px;
  }
}
@media print {
  .article-universe-teaser,
  .article-universes,
  .lp_relatedglobal,
  .article-comments,
  .menu-reference { display: none }
  aside.fact, aside.fact * {
    max-height: none !important;
  }
  aside.fact .fact__expand {
    display: none !important;
  }

  figure.image {
    background: #000;
  }
  .image-reference {
    page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-inside: avoid; /* or 'auto' */
  }
}


.note-container {
  display: inline;
  box-sizing: border-box;
  nrkno-toggle > .note-content {
    animation: fadeInFromTop 0.2s backwards;
    opacity: 1;
    transform: translateY(2px);
    z-index: 9999;
  }
  nrkno-toggle[hidden] > .note-content {
    opacity: 0;
  }

  .note-button[aria-expanded="true"] {
    background: #FFF7A3;
  }

  &.active .note-content {
    opacity: 1;
  }

  .note-button {
    display: inline;
    font-size: 100%;
    font-family: inherit;
    border: 0;
    padding: 0;
    background: none;
    color: #26292a;
    border-color: #26292a;
    border-bottom-style: dotted;
    border-bottom-width: 2px;
    font-style: inherit;
    font-weight: inherit;

    &:focus {
      outline: 2px dotted #26292a;
      border: none;
    }
  }


  .note-content {
    position: absolute;
    box-sizing: border-box;
    padding: 0 16px;
    font-size: 16px;
    background: #fff;
    max-width: 100%;
    min-width: 150px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    z-index: 3;
    word-break: break-word;
    font-style: initial;
    font-weight: initial;

    @media screen and (min-width: 720px) {
      max-width: 400px;
    }
    .note-close {
      position: absolute;
      right: 6px;
      top: 5px;
      font-size: 15px;
      color: #999;
      border: none;
      padding: 0;
      background: none;
    }
    h3 {
      margin: 10px 15px 0 0;
    }
    p {
      margin-top: 8px;
    }
  }
}
@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
}
