.live-header-wrapper {
	background: #98b1c9;
}

.live-header {
	height: 61px;

	position: relative;

	background: #98b1c9; /* Old browsers */
	background: -moz-linear-gradient(left,  #98b1c9 0%, #678ec2 100px, #678ec2 120px, #98b1c9 300px); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,#98b1c9), color-stop(100px,#678ec2), color-stop(120px,#678ec2), color-stop(300px,#98b1c9)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(left,  #98b1c9 0%,#678ec2 100px,#678ec2 120px,#98b1c9 300px); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left,  #98b1c9 0%,#678ec2 100px,#678ec2 120px,#98b1c9 300px); /* Opera 11.10+ */
	background: -ms-linear-gradient(left,  #98b1c9 0%,#678ec2 100px,#678ec2 120px,#98b1c9 300px); /* IE10+ */
	background: linear-gradient(to right,  #98b1c9 0%,#678ec2 100px,#678ec2 120px,#98b1c9 300px); /* W3C */
	filter: unquote("progid:DXImageTransform.Microsoft.gradient( startColorstr='#98b1c9', endColorstr='#98b1c9',GradientType=1 )"); /* IE6-9 */
}

.live-header > div > h1 {
	top: 14px;

	margin: 0;
	text-indent: -500px;
	color: transparent;
	background: #a93000 url(img/news-header-title.png) no-repeat 16px 8px;
	float: left;
	width: 113px;
	position: relative;
	line-height: 32px;
	left: 40px;
}

.live-header > div {
	position: absolute;
	top: 0;
	left: -40px;
	right: 0;
	bottom: 0;
	background: transparent url(img/news-header-background.png) no-repeat top left;
}

.live-header > div > h1 > a {
	color: transparent;
	display: block;
	height: auto;
}

@media screen and (min-width:720px) { 
	.live-header {
		height: 90px;
	}

	.live-header > div > h1 {
		top: 30px;
	}
}