@require '../../common/css/mixins';

html.darkmode {
  .schedule__calendar {
    background: $darkmode-background;
    color: $darkmode-text;
    border: 1px solid $darkmode-border;
    box-shadow: none;
    tbody tr td {
      border-color: $darkmode-border-light;
    } 
  }
  .schedule__menu {
    border-color: $darkmode-border;
  }
  .schedule__list {
    thead tr th {
      border-color: $darkmode-border-light;
    }
    tbody[aria-busy="true"]::before {
      background: $darkmode-border;
      -webkit-box-shadow: 0 0 0 5px $darkmode-border;
      box-shadow: 0 0 0 5px $darkmode-border
    }
    tbody {
      td, th {
        border-color: $darkmode-border-light;
      } 
      tr:nth-child(odd) {
        background: $darkmode-background;  
      }
      tr:hover {
        background: $darkmode-border;
      }
    }
  }
}

/**
 *	generic/mobile styles schedule
 **/


.schedule { margin: 0 10px }
.schedule__menu button { background: none; color: inherit; font: inherit; border: 0; margin: 0; padding: 0 }
.schedule table { border-collapse: collapse; border-spacing: 0; width: 100% }

.schedule__wrapper { overflow-x: auto; font-weight: 400; font-size: 1.05rem;}
.schedule__list { position: relative } /* To support position: absolute on a::before */
.schedule__list td,
.schedule__list th { text-align: left; font-size: .85em; padding: 7px }
.schedule__list td { cursor: pointer }
.schedule__list td svg { vertical-align: middle }
.schedule__list th + th
.schedule__list td + td { border-left: 1px solid #e0e0e0 }
.schedule__list th { border-bottom: 1px solid #e0e0e0 }
.schedule__list tbody tr { transition: .1s; animation: schedule-row 100ms backwards cubic-bezier(.1, .7, .3, .9); height: 40px;}
.schedule__list tbody tr:nth-child(odd) { background: #f6f6f6 }
.schedule__list tbody tr:hover { background: #e0e0e0 }
.schedule__list svg { font-size: 8px }
.schedule--live::after {
  display: inline-block;
  vertical-align: middle;
  content: 'Sendes nå'; /* For screen readers */
  font-size: 0; /* Visually hide text */
  margin: -2px 5px 0;
  border: 4px solid #e51c30;
  border-radius: 100%;
}

.schedule__list tbody[aria-busy="true"]::before {
  content: '';
  display: block;
  margin: 14px auto;
  width: 14px;
  height: 14px;
  border: 1px solid;
  border-top-color: transparent;
  border-radius: 50%;
  background: #e0e0e0;
  box-shadow: 0 0 0 5px #e0e0e0;
  animation: schedule-spin 1s infinite linear;
}

.schedule__menu {
  list-style: none;
  border-bottom: 1px solid #e0e0e0;
  margin: 20px 0;
  padding: 0;
}
.schedule__menu > li { display: inline-block }
.schedule__menu > li > button {
  text-transform: uppercase;
  margin-right: 13px;
  padding: 10px 0;
  font-weight: 600;
  font-size: 14px;
}

.schedule__date-button[data-current="true"] {box-shadow: inset 0 -2px }

.schedule__popup.schedule__popup { /* Double selector to increase spesificity */
  padding-right: 15px;
  background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23000000" viewBox="0 0 15 15"%3E%3Cpath stroke="%23000000" fill="none" stroke-linecap="round" d="M1.5 5l6 6 6-6"%3E%3C/path%3E%3C/svg%3E') no-repeat right center / 10px;
}

.schedule__calendar {
  position: absolute;
  z-index: 3;
  width: 300px;
  box-shadow: 0 7px 14px rgba(0,0,0,.2);
  background: #fff;
  transition: .2s;
}
.schedule__calendar[hidden] {
  display: block;
  visibility: hidden;
  transform: translateY(-9px);
  opacity: 0;
}
.schedule__calendar > button { position: absolute; font-size: 12px; padding: 9px }
.schedule__calendar > button + button { right: 0 }
.schedule__calendar th {
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
}
.schedule__calendar caption { text-transform: capitalize; padding: 9px }
.schedule__calendar td + td { border-left: 1px solid #e0e0e0 }
.schedule__calendar td { font-size: .9em; line-height: 2; border-top: 1px solid #e0e0e0 }
.schedule__calendar td button[data-core-datepicker-selected="true"] { box-shadow: inset 0 -2px }
.schedule__calendar td button:disabled { opacity: .2; cursor: not-allowed }
.schedule__calendar td button { width: 100% }

.schedule__show-more {margin: 10px 0;}
.schedule__show-more button {font-size: 14px;}

@keyframes schedule-spin { to { transform: rotate(360deg) } }
@keyframes schedule-row { from { opacity: 0; transform: translate3d(0, 2rem, 0) } }
@media(max-width: 530px) { .schedule__calendar { right: 9px } }
