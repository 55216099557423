@require '../../common/css/mixins';

// --- DARKMODE ---
html.darkmode {
  .quiz {
    .quiz-question__header__numbering {
      background-color: $darkmode-text;
      color: $darkmode-body-background;
    }
    .quiz-option {
      span {
        color: $darkmode-text;
      }
      background-color: $darkmode-background;
    }
    .quiz-solution {
      border-color: $darkmode-border;
    }
  }
}
// --- DARKMODE END ---

.quiz {
  position: relative;

  h2, h3, p, figure {
    margin: 0;
    padding: 0;
  }

  p {
    font-size: 18px;
    line-height: 1.652em;
  }

  .quiz-no-image-credit figcaption small {
    display: none;
  }

  .quiz-question {
    margin-bottom: 40px;
  }

  .quiz-question__header {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.3;
    margin: 12px 0;
    display: flex;
    align-items: baseline;
  }

  .quiz-question__header__numbering {
    display: inline-block;
    color: #fff;
    background-color: $nrk-color-core-blue-600;
    flex: 0 0 auto;
    border-radius: 50%;
    height: 42px;
    width: @height;
    line-height: @height;
    text-align: center;
    font-size: 14px;
    transform: translateY(-1px);
  }

  .quiz-question__header__title {
    padding-left: 10px;
  }

  .quiz-question__media {
    .image {
      margin: 5px 0 10px 0;
      width: 100%;
      height: auto;
    }
  }

  .quiz-question__body {
    margin: 12px 0 9px 0;
  }

  .quiz-question__options {
    margin: 12px 0;

    @media screen and (min-width: 500px) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  .quiz-option {
    font-size: 15px;
    position: relative;
    margin: 6px 0;
    padding: 13px 40px;
    width: 100%;
    background-size: 21px;
    white-space: normal !important;
    line-height: 1.3 !important;
    height: auto;
    min-height: 41px;

    span {
      color: #000;
    }
  }

  .quiz-option--incorrect-answer,
  .quiz-option--correct-answer {
    opacity: 1;

    span {
      position: relative;
    }

    span::before {
      position: absolute;
      top: 0;
      font-size: 0;
      margin: 0 -27px;
      width: 18px;
      height: @width;
      background: 50%/contain no-repeat;
      content: attr(data-content-text);
    }

    &:after {
      opacity: 1;
      border-width: 2px;
    }
  }

  .quiz-option--correct-answer {
    color: #3D8704;
    border-color: currentColor;

    span::before {
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 8l6 9L19 3' stroke-width='2' stroke='%233D8704' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ");
    }
  }

  .quiz-option--incorrect-answer {
    color: #990014;
    border-color: currentColor;

    span::before {
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23990014' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M3 18L18 3M18 18L3 3'/%3E%3C/g%3E%3C/svg%3E ");
    }
  }

  .quiz-solution {
    border: 1px solid #e9e9e9;
    margin-top: 12px;
    padding: 15px;

    h3 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 8px;
    }
  }

  .quiz-result {
    margin: 24px 0;

    h2 {
      margin: 12px 0;
      font-size: 22px;
    }
  }

  .quiz-result__body {
    margin: 12px 0;
  }

  .quiz-share-button.quiz-share-button {
    color: #fff;
    position: relative;
    width: 100%;
    margin: 6px 0;
    -webkit-font-smoothing: auto;
    svg {
      margin: -1px 6px 0;
    }
  }
}

/* Overrides for feature articles */
.article-feature .quiz {
  p {
    font-size: calc(18px + 0.05vw);
    line-height: 1.625em;
  }

  figcaption p {
    font-size: calc(15px + 0.05vw);
  }

  h2 {
    font-weight: 800;
    font-size: calc(1.35em + 0.3vw);
    line-height: 1.3;
  }

  .quiz-option {
    font-size: 17px;

    @media screen and (min-width: 500px) {
      font-size: 16px;
    }
  }

  .quiz-question__header__numbering {
    font-weight: 700;
  }

  .quiz-question__header__title {
    font-size: calc(0.94em + 0.1vw);
    line-height: 1.7;
  }

  .quiz-solution {
    padding: 20px 25px;

    h3 {
      font-size: calc(18px + 0.05vw);
      font-weight: 600;
    }
  }

  .quiz-question__body,
  .quiz-result__body,
  .quiz-result h2 {
    @media screen and (max-width: 899px) {
      margin-left: 25px;
      margin-right: @margin-left;
    }
  }

  .quiz-question__options,
  .quiz-share-buttons {
    @media screen and (max-width: 899px) {
      margin-left: 22px;
      margin-right: @margin-left;
    }
  }
}
