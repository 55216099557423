@require '../../common/css/mixins';

// --- DARKMODE ---
html.darkmode {
  .flow-relation.inverse {
    background: $darkmode-background;		
  }
	
  .flow-content > .col > .widget.reference {
    color: $darkmode-text;
    border-color: $darkmode-border-light;

    a {
      color: $darkmode-text;
      &:hover {
        text-decoration: underline
      }
    }
  }

	.group.floor.negative .nrk-pagination .nrk-button  {
		border-color: $darkmode-border-light;
		color: $darkmode-text;
	}
}
// --- DARKMODE END ---

/* ..because flow elements are inline blocks */
.flow-content > .col {
	display: inline-block;
	float: none;
	vertical-align: top;
}

.flow {
	margin-bottom: 20px;
}

.group.floor .flow > .container-widget-content > .widget-title {
	border-bottom-width: 0;
	//color: #9d9d9d;
}

.flow-swipe-pagination {
	display: none;
}

.flow-content > .col > .widget.reference {
	padding-bottom: 15px;
	padding-top: 15px;
	margin-bottom: 0;
	border-bottom: 1px solid #dddddd;
	display: block;
	font-size: 14px;
	color: #26292A;
}

.flow-content > .col > .widget.reference > a {
	display: block;
}

.flow-content > .col:first-child > .widget.reference {
	padding-top: 0;
}

.flow-content > .col:last-child > .widget.reference {
	margin-bottom: 15px;
	border-bottom-width: 0;
}

/* topmost floor displays different pagination; see also group.css */
.group.floor-top .pagination {
	margin: -11px 0 0 0px;
}

/* styling for negative button */
.group.floor.negative .nrk-pagination::before,
.group.floor.negative .nrk-pagination::after,
.group.floor.negative .nrk-pagination .nrk-button::after {
	display:none;
}

.group.floor.negative .nrk-pagination .nrk-button {
	max-width: none;
	width: 100%;
	border: 0 solid #484A4C;
	border-top-width: 1px;
	border-radius: 0;
	font-size: 13px;
	color: #fff;
	background-color: transparent;
	box-shadow: none;
	font-weight: 500;
}
.group.floor.negative .nrk-pagination .nrk-button:active {
	transform: translateY(2px);
	opacity: .8;
}

.no-js .flow .pagination {
	display:none;
}

@media screen and (min-width:720px) {
	.flow .pagination {
		margin-bottom: 30px;
	}

	.flow-swipe-pagination {
		display: none;
	}

	.group.floor-top .pagination {
		margin: 0 15px;
	}

	.group.floor.negative .nrk-pagination .nrk-button {
		font-size: 16px;
	}

}

.flow-relation.inverse {
	background: #26292a;
	position: relative;
	top: -15px;
	padding-top: 15px;

	.widget:not(.plug-feature) {
		.title, .plug-preamble {
			color: #fff;
		}
	}
}

@media print {
	.flow {
		display: none;
	}
}
