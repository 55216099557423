.mai-header-wrapper {
	display: none;
}
@media screen and (min-width: 720px){
	.mai-header-wrapper {
		display: block;
		max-width: 1180px;
		margin: 0 auto;
		position: relative
	}
	.mai-header-image {
		position: absolute;
		top: 0;
		right: 15px;
		width: 677px;
		height: 174px;
		background: url('img/header.png') no-repeat;
	}
}