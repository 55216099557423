@require "./link-container.styl";


.link.reference {
	text-decoration: none;
    margin: 0.2em 0 1em;
}

html.darkmode {
  .link.reference strong {
    color: $darkmode-text;
  }
}

.link.reference strong {
	color: #222;
}

.link.reference	span {
	text-decoration: underline;
}
