/* kolonner danner grid */
.col {
	width: 100%;
	float: left;
}

.spacer {
	height: 1px;
}

/* disse kolonnene tegnes ut i en av 18 størrelser */
.nostack .g100, .nostack.g100 { width: 100%; }           /* s18 */
.nostack .g95, .nostack.g95   { width: 95%; }
.nostack .g94, .nostack.g94   { width: 94.4444444444%; } /* s17 */
.nostack .g90, .nostack.g90   { width: 90%; }
.nostack .g89, .nostack.g89   { width: 89%; }
.nostack .g88, .nostack.g88   { width: 88.8888888889%; } /* s16 */
.nostack .g85, .nostack.g85   { width: 85%; }
.nostack .g83, .nostack.g83   { width: 83.3333333333%; } /* s15 */
.nostack .g80, .nostack.g80   { width: 80%; }
.nostack .g77, .nostack.g77   { width: 77.7777777778%; } /* s14 */
.nostack .g75, .nostack.g75   { width: 75%; }
.nostack .g72, .nostack.g72   { width: 72.2222222222%; } /* s13 */
.nostack .g70, .nostack.g70   { width: 70%; }
.nostack .g66, .nostack.g66   { width: 66.666666666%;  } /* s12 */
.nostack .g65, .nostack.g65   { width: 65%; }
.nostack .g61, .nostack.g61   { width: 61.1111111111%; } /* s11 */
.nostack .g60, .nostack.g60   { width: 60%; }
.nostack .g55, .nostack.g55   { width: 55.5555555556%; } /* s10 */
.nostack .g50, .nostack.g50   { width: 50%; }            /* s9  */
.nostack .g45, .nostack.g45   { width: 45%; }
.nostack .g44, .nostack.g44   { width: 44.4444444444%; } /* s8  */
.nostack .g40, .nostack.g40   { width: 40%; }
.nostack .g38, .nostack.g38   { width: 38.8888888889%; } /* s7  */
.nostack .g35, .nostack.g35   { width: 35%; }
.nostack .g33, .nostack.g33   { width: 33.333333333%;  } /* s6  */
.nostack .g30, .nostack.g30   { width: 30%; }
.nostack .g27, .nostack.g27   { width: 27.7777777778%; } /* s5  */
.nostack .g25, .nostack.g25   { width: 25%; }
.nostack .g20, .nostack.g20   { width: 20%; }
.nostack .g16, .nostack.g16   { width: 16.6666666667%; } /* s4  */
.nostack .g15, .nostack.g15   { width: 15%; }
.nostack .g22, .nostack.g22   { width: 22.2222222222%; } /* s3  */
.nostack .g11, .nostack.g11   { width: 11.1111111111%; } /* s2  */
.nostack .g10, .nostack.g10   { width: 10%; }
.nostack .g5,  .nostack.g5    { width: 5.55555555556%; } /* s1  */

/* under 720px kollapser kolonnene til 100 prosent, med mindre de er barn av et `.no-stack`-element */
@media screen and (min-width: 720px) {
	.g100 { width: 100%; }           /* s18 */
	.g95  { width: 95%; }
	.g94  { width: 94.4444444444%; } /* s17 */
	.g90  { width: 90%; }
	.g89  { width: 89%; }
	.g88  { width: 88.8888888889%; } /* s16 */
	.g85  { width: 85%; }
	.g83  { width: 83.3333333333%; } /* s15 */
	.g80  { width: 80%; }
	.g77  { width: 77.7777777778%; } /* s14 */
	.g75  { width: 75%; }
	.g72  { width: 72.2222222222%; } /* s13 */
	.g70  { width: 70%; }
	.g66  { width: 66.666666666%;  } /* s12 */
	.g65  { width: 65%; }
	.g61  { width: 61.1111111111%; } /* s11 */
	.g60  { width: 60%; }
	.g55  { width: 55.5555555556%; } /* s10 */
	.g50  { width: 50%; }            /* s9  */
	.g45  { width: 45%; }
	.g44  { width: 44.4444444444%; } /* s8  */
	.g40  { width: 40%; }
	.g38  { width: 38.8888888889%; } /* s7  */
	.g35  { width: 35%; }
	.g33  { width: 33.333333333%;  } /* s6  */
	.g30  { width: 30%; }
	.g27  { width: 27.7777777778%; } /* s5  */
	.g25  { width: 25%; }
	.g20  { width: 20%; }
	.g16  { width: 16.6666666667%; } /* s4  */
	.g15  { width: 15%; }
	.g22  { width: 22.2222222222%; } /* s3  */
	.g11  { width: 11.1111111111%; } /* s2  */
	.g10  { width: 10%; }
	.g5   { width: 5.55555555556%; } /* s1  */

	.flow .g50 {
		width: 49.99%;
	}
}

/* Vi opererer med to ulike innholdselementer:
 *
 * - container-widget
 * - widget
 *
 * En container-widget "rammer" inn andre widgets. Widgets som er barn av slike container-widgets tegner ut luft, 10px på mobil.
 */
.container-widget > .col > .widget {
	margin: 0 10px 25px 10px;
}

@media screen and (min-width: 720px) {

	/* ...og tilsammen 30px rundt hver widget på desktop */
	.container-widget > .col > .widget {
		margin: 0 15px 30px 15px;
	}
}

/* VIKTIG: container-widgets tegener _ikke_ ut luft */
.container-widget {
	margin: 0 !important;
	/*outline: 0px solid green !important;*/
}

/* container-widgets inneholder av og til innhold (titler o.l.). Bruk da `.container-widget-content`-klassen */
.container-widget-content {
	margin: 0 10px 10px 10px;
}

@media screen and (min-width: 720px) {

	.container-widget-content {
		margin: 0 15px 10px 15px;
	}

}

/* grupper i full bredde på øverste nivå i dokumentet har maksimumsbredde, og blir midstilt... */
#container > .group-reference.sAUTO > .group > .group-reference.s18,
#container > .group-reference.sAUTO > .group > .group-reference.s12,
#container > .group-reference.s18,
#container > .group-reference.s12,
.grid {
	margin: 0 auto 0 auto;
	max-width: 1180px;
	float: none;
	clear: both;
}

/* ...med mindre gruppen er venstrestilt... */
#container > .group-reference.sAUTO.fl > .group > .group-reference.s18,
#container > .group-reference.sAUTO.fl > .group > .group-reference.s12,
#container > .group-reference.s18.fl,
#container > .group-reference.s12.fl {
	margin: 0 0 0 0;
}

/* ...eller høyrestilt */
#container > .group-reference.sAUTO.fr > .group > .group-reference.s18,
#container > .group-reference.sAUTO.fr > .group > .group-reference.s12,
#container > .group-reference.s18.fr,
#container > .group-reference.s12.fr {
	margin: 0 0 0 0;
	float: right;
}

/* gruppe-elementer eller surrogate-elementer som ligger på øverste nivå har luft på overkant, slik som "vanlige" widgets, slik at summen av luft blir lik (10 + 10 = 20)  */
#container > .group-reference.sAUTO > .group > .group-reference.s18 > .group,
#container > .group-reference.sAUTO > .group > .group-reference.s12 > .group,
#container > .group-reference.s18 > .group,
#container > .group-reference.s12 > .group,
.group.wrapper {
	padding-top: 10px;
}


@media screen and (min-width: 720px) {

	#container > .group-reference.sAUTO > .group > .group-reference.s18 > .group,
	#container > .group-reference.sAUTO > .group > .group-reference.s12 > .group,
	#container > .group-reference.s18 > .group,
	#container > .group-reference.s12 > .group,
	.group.wrapper {
		padding: 30px 0px 0 0px;
	}
}

/* 12 + 6-kolonnere som er barn av `fluid-align` sørger for at 6-kolonneren alltid er like høy som 12-kolonneren */
@media screen and (min-width: 720px){
	.fluid-align {
		position: relative;
	}
	.fluid-align > .g66,
	.fluid-align > .g33 {
		width: 100%;
	}
	.fluid-align > .s6,
	.fluid-align > .s6 > .widget {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
	}
	.fluid-align > .s6 > .widget {
		left: 0;
		overflow: auto;
	}
	.fluid-align > .g66 {
		width: 100%;
	}
	.fluid-align > .g33 {
		width: 100%;
		position: relative;
	}
	.fluid-align > .g33 > .widget {
		position: relative;
	}
}

@media screen and (min-width: 769px){
	.fluid-align > .g66 {
		width: 66.666666666%;
	}
	.fluid-align > .g33 {
		width: 33.333333333%;
	}
	.fluid-align > .g33 > .widget {
		position: absolute;
	}
}

