@require '../../common/css/mixins';
/* serum css overides */
/* empty `<p>` tags being display:none for XL */
.article-feature__body {
  > .article.widget.reference + p:empty {
    display: block;
  }
}

/* override last `<p>` not having margin */
.text-body {
  .article.widget.reference + p:last-child {
    margin-bottom: 1.5em;
  }
}

/* handle embeds on a single line in polopoly */
.article.widget.reference + .article.widget.reference {
  > a[href].link {
    &__container {
      margin-top: 1.5rem;
    }
  }
}

/* increase specificity */
a[href].link {
  &__container {
    display: flex;
    flex-direction: row;
    /* override serum */
    border-bottom: none;
    /* NRKNO-5433: Ignore potential parent `<em>` */
    font-style: normal;
    /* Anchor styling */
    text-decoration: none;

    margin-bottom: 1px;
    padding-bottom: 0;
    &:hover {
      border-bottom: none;
      .heading__container {
        .heading {
          .link-text {
            text-decoration: underline;
          }
        }
      }
    }
    .svg-container {
        min-width: 49px;
        position: relative;
        overflow: hidden;

        svg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: calc(100% + 1px);
          object-fit: cover;
        }
      }

    &--with-thumbnail {
      overflow: hidden;

      .heading__container {
        flex-basis: 63%;
        position: relative;
      }
      .thumbnail-overlay {
        position: absolute;
        top: 0;
        left: calc(100% - 1px); // 1px to prevent gap between text and overlay
        height: calc(100% + 1px); // 1px to prevent image peeking through below overlay
        z-index: 1;
      }

      .thumbnail-container {
        flex-basis: 37%;
        position: relative;
        overflow: hidden;
        max-width: 100%;
        height: auto;
        z-index: 0;

        .responsive-img img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .responsive-img.F169 {
          padding-bottom: 56.25%;
        }
        .responsive-img.F34 {
          padding-bottom: 133.33%;
        }
        .responsive-img.F11 {
          padding-bottom: 100%;
        }

        .desktop-only {
          display: none;
        }
        @media (min-width: 720px) {
          .mobile-only {
            display: none;
          }
          .desktop-only {
            display: block;
          }
        }
      }
    }

    .heading__container {
      display: flex;
      padding: 16px;
      padding-right: 1px;
      margin: 0;
      flex: 1;

      .kicker {
        font-weight: 640;
        display: block;
        font-size: 14px;
        line-height: 1.3;
        margin-bottom: 0.25rem;
      }
      .heading {
        font-size:18px;
        line-height: 1.25;
        font-weight: 800;
        align-self: center;
        font-variation-settings: 'wdth' 90;
        width: 100%;
        margin: 0;

        span {
          text-decoration: none;
        }
      }
    }
  }
}
