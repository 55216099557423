@require 'mixins';

// --- DARKMODE ---
html.darkmode {
	.plug-teaser {
		&:before {
			color: $darkmode-text;
		}
		color: $darkmode-text;
		.plug-preamble {
			color: $darkmode-text;
		}

		.plug-metadata.plug-byline {
			color: $darkmode-text-byline
		}
	}

.plug-feature:not(.reference.plug) {
  .plug-teaser, .review-summary {
    background: $darkmode-background;
  }
}


  .negative .plug.plug-feature {
    .plug-teaser {
      background: $nrk-color-core-blue-900;
    }
    .title, .plug-preamble {
      color: $darkmode-text;
    }
    & a:not([href=""]):hover {
      .title, .link {
        color: $darkmode-text;
      }
    }
  }

  .negative .plug .title,
  .negative .brief .title {
    color: $darkmode-text;
  }

  .negative .plug a:not([href=""]):hover {
    .title, .link {
      color: $darkmode-text
    }
  }

  .plug-metadata {
    color: $darkmode-text;
  }

  .plug-metadata-footer {
    border-color: $darkmode-border;
  }
}

.darkmode { // decreased specificity to allow for overrides
  .plug-feature {
    .plug-teaser:not(class*='skin-background') {
      background-color: $darkmode-background;
    }
  }
}

// --- DARKMODE END ---

.numbered-heading {
  .article.widget.reference.plug, .sanitycontent.widget.reference.plug, .link.widget.reference.plug {
    width: 100%;
  }
}


/* PLUG: defines common styles for "plug-like" content such as articles, videos etc. */

// Reference-plugs
.article.widget.reference.plug, .sanitycontent.widget.reference.plug, .link.widget.reference.plug {
    border: none;

    .link__container {
      width: 100%;
    }

// Plugs should not be italic or bold, but follow the plug-styling
  strong, b, em, i {
    font-weight: normal;
    font-style: normal;
  }

  &.plug-feature .plug-teaser {
    box-shadow: none
  }

  .plug-teaser {
    padding: 16px;
  }

  .title {
    font-size: 24px;
    font-weight: 800;
    margin: 0;
  }

  &.review-plug {
    text-align: center;
    }

  .plug-image {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
  .review-summary {
  margin-top: -12px;
  }

  .plug-preamble {
    font-size: 16px;
    }
}


.negative .plug .title,
.negative .brief .title {
	color: $color-white;
}

.plug .title {
	line-height:1.16;
	margin:0.3em 0 0.2em 0;
	font-weight: 740;
}

.plug {
	a .widget:not(.video) { // see PUB-12098 and PUB-12045
		position: relative;
    z-index: 1;

		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1000;
		}
	}
}

.plug a:not([href=""]):hover {
	.title, .link {
		text-decoration: underline !important;
	}
}

.negative .plug a:not([href=""]):hover {
	.title, .link {
		color: #F0F0F0;
	}
}

.plug-image {
  margin-bottom: 8px;
}

.plug-teaser {
  position: relative;
  z-index: 2;
}

.plug-teaser h2 {
font-variation-settings: "wdth" 90;
}

.plug-teaser .review-summary~h2, .plug-teaser h2.recipe {
font-variation-settings: "wdth" 100;
}

.plug-preamble {
	margin: 5px 0 0 0;
	line-height: 1.5em;
	font-size: 16px;
  color: $text-color;

	-moz-hyphens: auto;
	-ms-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
	word-wrap: break-word;
}

.negative .plug-preamble {
	color: #F0F0F0;
}

.plug-byline {
	display: inline-block;
	line-height: 20px;
	display: none;
}

.plug-preamble > span:first-child {
	font-weight: bold;
	padding-left: 13px;
}

.plug-metadata {
	font-size: 12px;
	color: #555;
	text-transform: uppercase;
}

.negative .plug-metadata {
	color: #F0F0F0;
}

.plug-footer {
	margin-top: 8px;
}

.plug-metadata-footer {
	overflow: hidden;
	border-left: 5px solid #e0e0e0;
	padding: 3px 0 3px 8px;
}

.plug-metadata-icon {
	display: inline-block;
	background-position: 0 4px;
	background-repeat: no-repeat;
	padding-left: 16px;
	margin-right: 10px;
	line-height: 20px;
}
.plug-metadata-icon-album { background-position: 0 -506px; padding-left: 21px }
.plug-metadata-icon-audio { background-position: 1px -334px }
.plug-metadata-icon-discussion { background-position: 0 -62px }
.plug-metadata-icon-external-play { background-position: 0 -419px; padding-left: 22px }
.plug-metadata-icon-video { background-position: 1px -85px }
.plug-metadata-icon-gallery { background-position: 0 -39px }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.plug-metadata-icon-album { background-position: 0 -266px }
	.plug-metadata-icon-audio { background-position: 1px -184px }
	.plug-metadata-icon-discussion { background-position: 0 -118px }
	.plug-metadata-icon-external-play { background-position: 0 -226px }
	.plug-metadata-icon-gallery { background-position: -1px -94px }
	.plug-metadata-icon-video { background-position: 0 -141px }
}

.plug-image.plug-image-play {
	position: relative;
}

.plug-image.plug-image-play:before {
	position: absolute;
	content: '';
	height: 60px;
	width: 60px;
	background: transparent url(../../type/video/img/plug-play.png) no-repeat center center;
	left: 10px;
	bottom: 10px;
	z-index: 1;
}

.sl9 > .plug .plug-image {
	width: 42%;
	float: left;
	margin-right: 13px;
}

.sl9 > .plug .title {
	position: relative;
	top: -3px;
	font-size: 21px;
}

.sl9 > .plug .plug-preamble {
	display: block;
	clear: both;
}


@media screen and (min-width: 720px) {
	.sl9 > .plug .title {
		position: static;
	}

	.sl9 > .plug .plug-image {
		width: 100%;
		float: none;
		margin-left: 0;
	}

	.s1 > .plug .title,
	.s2 > .plug .title,
	.s3 > .plug .title,
	.s4 > .plug .title {
		font-size: 19px;
	}

	.s5 > .plug .title,
	.s6 > .plug .title {
		font-size: 24px;
	}

	.s7 > .plug .title,
	.s8 > .plug .title,
	.s9 > .plug .title {
		font-size: 30px;
	}

	.s10 > .plug .title,
	.s11 > .plug .title,
	.s12 > .plug .title,
	.s13 > .plug .title,
	.s14 > .plug .title {
		font-size: 36px;
		line-height: 1;
	}

	.s15 > .plug .title,
	.s16 > .plug .title,
	.s17 > .plug .title {
		font-size: 40px;
		line-height: 1;
	}

	.s18 > .plug .title {
		font-size: 44px;
		line-height: 1;
	}

	.flow-content > .s18 > .plug {
		position: relative;
	}

	.flow-content > .s18 > .plug .title {
		margin-top:0;
	}

	.flow-content > .s18 > .plug .plug-image {
		width: 60%;
		float: left;
		margin-bottom: 0;
	}

	.flow-content > .s18 > .plug .plug-teaser {
		width: 40%;
		float: right;
	}

	.flow-content > .s18 > .plug .plug-teaser > .plug-metadata,
	.flow-content > .s18 > .plug .plug-teaser > .title,
	.flow-content > .s18 > .plug .plug-teaser > .plug-preamble,
	.flow-content > .s18 > .plug .plug-teaser > .plug-footer {
		margin-left: 20px;
	}

	.flow-content > .s18 > .plug .plug-preamble {
		display: block;
	}

	.article.widget.reference.plug .title,
	.sanitycontent.widget.reference.plug .title,
	.link.widget.reference.plug .title {
		font-size: 32px;
	}
}



// PUB-12943-xl-visnig-i-plugger-til-xl-artikler
// Use skin-color in cases of reference-plugs, white elsewhere


.plug-feature:not(.reference.plug),  {
  .plug-teaser, .review-summary {
		background: $color-white;
  }
}
.plug-feature {
	.plug-teaser {
		box-shadow: 0 -10px 15px -7px rgba(0,0,0, 0.25), 0 2px 5px -1px rgba(0,0,0,0.1);
		margin: -30px 15px 20px;
		text-align: center;
		padding: 1rem 1.0625rem;
	}
	.title {
		font-weight: 800;
		padding: 0 2%;
		margin: 0 0 6px;
	}
	.plug-preamble {
		padding: 0 2%;
	}
	.plug-metadata-footer {
		border-left: 0;
	}
}
.sl9 > .plug-feature .plug-image {
	width: auto;
	float: none;
	margin-right: 0;
}

.negative .plug.plug-feature {
	.title, .plug-preamble {
		color: $text-color;
	}

	& a:not([href=""]):hover {
		.title, .link {
			color: $text-color;
		}
	}
}

@media screen and (min-width: 720px) {
	.flow-content > .s18 > .plug-feature .plug-image {
		width: auto;
		float: none;
	}
	.flow-content > .s18 > .plug-feature .plug-teaser {
		margin: -30px 15px 20px;
		width: auto;
		float: none;
	}
}
