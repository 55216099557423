// GENERATE DEFAULT SKIN STYLES
@require "mixins";
@require "skin-colors/default-skin";

/* change background image sprite to use different skin color */
.skin-plug-icon {
	background-image: url(../img/plug-sprite.png);
	background-repeat: no-repeat;
	background-position: 0 4px;
}

// default grey fill for svg, not sure where to put this
.gray-fill {
	fill: $nrk-color-gray-600;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.skin-plug-icon {
		background-size: 16px auto;
		background-position: 0 -52px;
	}
}

// skin loader styles
@keyframes rotate {
	to { transform: rotate(360deg); }
}

@media print {
  .no-print-images .image,
  .no-print-images .album,
  .no-print-images .video,
  .no-print-images picture,
  .no-print-images img{
    display: none!important;
  }
}

.no-js .skin-loader {
	display: none;
}

.skin-loader,
.skin-loader-small {
	vertical-align: middle;
	text-align: center;
	font-size: 75%;
	line-height: 2;
	color: rgba(38, 41, 42, 0.8);
}

.negative .skin-loader,
.negative .skin-loader-small {
	color: $nrk-color-gray-200;
}

.skin-loader::before,
.skin-loader-small::before{
	content: url(img/loader.gif);
	display: block;
	margin: auto;
	width: 40px;
	height: 40px;
	line-height: 0; /*Centers svg-content*/
}

.skin-loader-small::before {
	content: url(img/loader_small.gif);
	width: 15px;
	height: 15px;
	margin: -2px;
}

.skin-loader::before,
.skin-loader-small::before {
	content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 44 44'%3E%3Cpath fill='none' stroke='%23333' stroke-width='1.2' d='M33 22c0 6-5 11-11 11s-11-5-11-11 5-11 11-11c3.2 0 6 1.4 8 3.6m0-4.6v5h-5'/%3E%3C/svg%3E");
}

.negative .skin-loader::before,
.negative .skin-loader-small::before {
	content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 44 44'%3E%3Cpath fill='none' stroke='%23c8c8c8' stroke-width='1.2' d='M33 22c0 6-5 11-11 11s-11-5-11-11 5-11 11-11c3.2 0 6 1.4 8 3.6m0-4.6v5h-5'/%3E%3C/svg%3E");
}

.skin-loader::before,
.skin-loader-small::before {
	animation: rotate 1.3s infinite linear;
}
