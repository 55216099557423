/* Factboxes
------------------------------------------------------------ */

$expand-btn-height = 40px;

.fact {
	box-sizing: border-box;
	position: relative;
	margin-bottom: 1em;
	font-size: 16px;
	line-height: 1.65;
	word-wrap: break-word;

  &:not(.fact-full) {
	margin-bottom: "calc(calc(%s * 0.5) + 1em)" % $expand-btn-height;
  }

	.fact__title.fact__title {
		padding: 27px 20px 0;
		font-size: 22px;
		color: inherit;
	}

	.fact__title.fact__title, h2 {
		font-weight: 740;
		font-variation-settings: 'wdth' 90;
	}

	&:not(.fact--expanded):not(.fact--full) {
		.fact__body > *:first-child {
			margin-top: 0;
			padding-top: 0;
		 }
	}

}

.fact .fact__body * {
	transition: margin-top 0.5s;
}

.fact .fact__body h2 {
	font-size: inherit;
	margin: 0;
}

.fact .fact__body > h2 {
	font-size: 17px;
	margin-left: 0;
	margin-bottom: 4px;
	line-height: 1.2;
}


.fact__cover { max-height: 0; transition: max-height .5s }
.fact__cover figcaption { margin: 0 20px}

.fact__expand.fact__expand {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	border: 0;
	max-height: 25px;
	background-color: inherit;

	.fact a[href]&, .fact a[href]:hover& .darkmode .fact a[href]&, .darkmode .fact a[href]:hover& {
		border-bottom: 0;
		}
}
.fact__expand div {
	text-align: center;
	display: block;
	transition: 0.2s;
	border-radius: 100px;
	margin: 5px auto 0;
	height: $expand-btn-height;
	position: relative;
	width: fit-content;
  padding: 0 24px;
  font-weight: 640;
  align-content : center;
}

.fact__body {
	overflow: hidden;
	transition: max-height .5s;
	padding: 0 20px 30px;
	max-height: 80px;
}
.fact--expanded.fact .image,
.fact--full.fact .image {
	margin-bottom: 0;
}

.fact--expanded .fact__body > :first-child {
	margin-top: 15px;
}

.fact--expanded.fact .image {
	transition: margin-top margin-bottom 0.5s;
}
.fact--expanded.fact .image,
.fact--full.fact .image {
	margin-top: 10px;
}

.fact--expanded {
	& .fact__expand {
	background: none;
	}
	& .fact__expand div {
		margin-top: 25px;
	}
	& .fact__body {
		padding-bottom: 45px;
	}

}

/*** Context specific text size overrides ***/

/* feature articles (aka XL template) */
.article-feature .fact__body.fact__body,
.article-feature .fact__body.fact__body p {
	font-size: calc(13px + .15vw);
	line-height: 1.65em;
}
.article-feature .fact__title.title {
	font-size: 20px;
}

/* Toggle
------------------------------------------------------------ */
.fact:target .fact__body, .fact--expanded .fact__body,
.fact:target .fact__cover, .fact--expanded .fact__cover { max-height: 2000px }

.fact:target .fact__expand div::before  {
	margin-top: 20px; /* Margin top controlls fade-out-height */
}

/* Desktop
------------------------------------------------------------ */
@media(min-width: 720px){
	.fact--full .fact__body,
	.fact--full .fact__cover { max-height: none }
	.fact--full .fact__expand { display: none }

	.fact--full.fact .fact__title {
		padding-top: 30px;
	}

	.fact--full .fact__body > :first-child {
		margin-top: 15px;
	}

	.article-feature .fl .fact-reference,
	.article .fact-reference.g100 {
		margin-left: -20px;
		margin-right: -20px;
		width: calc(100% + 40px);
	}

	.fact-reference.g100 .image,
	.article-feature .fl.fact-reference .image {
		margin-top: 12px;
		margin-left: 20px;
		margin-right: 20px;
	}

	.fact-reference.g100 .image figcaption,
	.article-feature .fl.fact-reference .image figcaption {
		margin-left: 0;
	}

	.article-sidebar .fact .image figcaption,
	.fact-reference.g100 .image figcaption,
	.article-feature .fl .fact .image figcaption {
		padding-top: 6px;
	}
}


/* Mobile only
------------------------------------------------------------------------------*/
@media screen and (max-width: 719px) {
	.article-sidebar {
		> .fact:not(.full-fact) {
			margin-bottom: 40px;
		}
	}
}

@media(max-width: 899px){
	.article-feature {
		.fact__title.title {
			font-size: 21px;
		}

		.fact__body.fact__body,
		.fact__body.fact__body p{
			font-size: 16px;
		}

		.fact__body > h2 {
			font-size: 18px;
		}

		.fact:not(.fact--expanded) {
			.fact__body > :first-child {
				margin-top: 0;
			}
		}

	}
}
