@require 'mixins';

// --- DARKMODE ---
html.darkmode {
  .widget-title {
    color: $darkmode-text;
    border-color: $darkmode-border;
  }
  a.skin-text-hover {
    color: $darkmode-text;
  }
  .text-body,
  .bulletin-text-body {
    color: $darkmode-text;
    .text-body-link,
    a {
      color: $darkmode-link;
    }
  }
  .text-body > h2 {
    color: inherit;
  }
  .text-body > p {
    color: inherit;
  }
}
// decreased specificity to allow for overrides
.darkmode {
  .title {
    color: $darkmode-text;
  }
}

// --- DARKMODE END ---

/* NRK Sans all the things */
body,
button,
input,
select,
textarea {
	font-nrk-sans();
  color: $text-color;
}

.title {
	font-nrk-sans(22px, 1.23*22);
	text-decoration: none !important;

	display: block;
	margin: 0 0 0.3em 0;
	color: $text-color;

	-webkit-hyphens: manual;
	-moz-hyphens: manual;
	-ms-hyphens: manual;
	hyphens: manual;
	word-wrap: break-word;

	text-rendering: optimizelegibility;
	font-weight: 740;
}

.negative .title {
	text-rendering: auto;
}

.title-large {
	font-nrk-sans();
	fontSize(32px, 36px, -0.035em);
	font-weight: 740;
	margin-bottom: 15px;
	margin-top: 15px;

	@media screen and (min-width: 480px) {
		fontSize(38px, 44px);
	}

	@media screen and (min-width: 720px) {
		fontSize(50px, 58px);
	}

	@media screen and (min-width: 850px) {
		fontSize(52px, 60px);
	}
}

// general link styling
a {
	color:inherit;
	text-decoration:none;
}

// Fix for empty href
a[href=""]{
	cursor:default;
}

// add color to links in text blocks
.text-body .text-body-link,
.text-body a {
	color: $nrk-color-core-blue-600;
	border-bottom: 1px solid $nrk-color-gray-300;
  padding-bottom: 2px; // descending letters (g,j,p,q,y) touches the border without this padding.
}

.text-body .text-body-link:hover,
.text-body a:hover {
	border-bottom: 1px solid $text-color-anchor;
}

a.skin-text-hover {
	color: $text-color;
}

.text-body {
	color: $text-color;
	font-size: 18px;
	counter-reset: numbered-heading;
}

.text-body-sans-serif {
	font-nrk-sans();
}

.text-body > p,
.text-body > ul,
.text-body > ol {
	margin: 0 0 1em 0; /* 22px */
	font-weight: 400;
	line-height: 1.55;
}

.text-body > ul,
.text-body > ol {
	padding-left: 1.875em;
}

.text-body > ul li,
.text-body > ol li {
  margin-top: 0.25rem;
}
.text-body > ol li::marker {
  font-weight: 700;
  font-size: 0.875em;
}

.text-body > p:last-child {
	margin-bottom: 0;
}

.text-body > h2 {
	margin: 0 0 0.5em 0; /* 11px */
	padding-top: .5em; /* 11px */
	color: $text-color;
	font-size: 21px;
	font-weight: 720;
	line-height: 1.25em;
	text-rendering: optimizelegibility;
}
.text-body > h2 > strong {
	font-weight: inherit;
}

.text-body > h2:first-child {
	padding-top: 0;
}

.text-body > h3 {
	font-size: 18px;
	margin: 0 0 .25em 0;
	padding-top: .5em;
	font-weight: 700;
	line-height: 1.3;
}
.text-body > h3 > strong {
	font-weight: inherit;
}

.text-body > h2 + h3 {
	padding-top: 0;
}

.text-body > h2.numbered-heading {
	counter-increment: numbered-heading;
	display: flex;
	margin-bottom: 0.5rem;
	align-items: baseline;
}

.text-body > h2.numbered-heading::before {
	content: counter(numbered-heading);
	margin-right: 0.5em;
	width: 1.727em; // 38px
	height: 1.727em; // 38px
	background-color: $text-color;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 999px;
	color: $color-white;
	margin-left: -0.125rem;
	flex-shrink: 0;
  box-sizing: content-box;
}

.text-body > h2.numbered-heading[data-starts-with-num]::before {
  content: attr(data-starts-with-num);
}

.text-body > h2.numbered-heading.numbered-heading--with-slash::before {
  font-size: 1rem;
  padding: 0.271rem;
}

.text-body > h2.numbered-heading.numbered-heading--long-number::before {
  font-size: 0.675rem;
  padding: 0.55rem;
}


/* normalize font size for embedded elements */
.text-body > .col {
	font-size: 1rem;
}

/* embedded block elements -- full width */
.text-body > .g100 {
	margin-bottom: 2em;
}

/* ...except when preceeded by title */
.text-body > h2 + .g100 {
	margin-bottom: .7em;
}

/* don't float 100% items */
.text-body > .col.g100 {
	float: none;
	clear: both;
}

/* embedded block elements -- left aligned */
.text-body > .col.fr {
	margin: .4em 0 1em 1.5em;
	clear: right;
}

/* embedded block elements -- left aligned */
.text-body > .col.fl {
	margin: .4em 1.5em 1em 0;
	clear: left;
}

.text-body > .col:last-child {
	margin-bottom: 0;
}

.widget-title {
	margin: 0 -10px 20px -10px;
	padding: 0 0 3px 10px;
	font-size: 13px;
	text-transform: uppercase;
	color: $text-color;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	font-weight: normal;
}

// override color when on dark background
.negative .widget-title {
	color:$nrk-color-gray-200;
	border-color:$nrk-color-gray-600;
}

@media screen and (min-width: 720px) {
	.widget-title {
		margin-left: 0;
		margin-right: 0;
		padding: 0 0 3px 0;
	}
}

.caption-cite {
	font-size: 11px;
	line-height: 14px;
	text-transform: uppercase;
	color: $nrk-color-gray-600;
	margin: 0;
	padding: 0;
}

.icon {
	width: 1em;

	display: inline-block;
	position: relative;
	vertical-align: middle;
}

.icon > svg {
	height: 100%;
	position: relative;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.icon > svg {
		top: .001em;
	}
}

/* Hide text content, but still show screen readers */
.sr-only {
	display: block;
	position: absolute;
	overflow: hidden;
	width: 1px;
	height: 1px;
	clip: rect(0 0 0 0);
	border: 0;
	padding: 0;
	margin: 0 -1px -1px 0;
}
