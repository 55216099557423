@require '../../common/css/mixins';

// --- DARKMODE ---
html.darkmode {
  .bulletin {
    &.emphasis-high {
			  .bulletin-attachment.plug {
					border: none;
					background: $nrk-color-warm-pink-900;
					color: $darkmode-text;

					.title,
					.plug-teaser:before {
						color: $darkmode-text;
					}
				}
      background-color: $nrk-color-warm-pink-950;
      .bulletin-circle {
        background-color: $nrk-color-warm-pink-950;
      }
      .bulletin-text a {
        color: white;
      }
			.teaser.reference.emphasis-high {
				background-color: $nrk-color-warm-pink-900;
				&:hover {
					background-color: $nrk-color-warm-pink-800;
				}
			}
			.widget {
				figcaption {
					small {
						color: $color-white;
					}
					color: $color-white;
				}
			}
    }

    a:hover .plug-svg-arrow,
    .plug-svg-arrow {
      color: $darkmode-text;
    }
  }

	.bulletin-circle {
		background: $darkmode-body-background
	}


	.bulletin-attachment.plug {
		border: none;
		background: $darkmode-background;
    color: $darkmode-text;

    .title,
    .plug-teaser:before {
      color: $darkmode-text;
    }
  }

  .surrogate > .teaser-reference > .bulletin {
    border-color: $darkmode-border-light;
  }

  .stream-item:first-child .bulletin:not(.emphasis-high) .bulletin-circle:after {
    background: $darkmode-body-background
  }

  .surrogate > .teaser-reference > .bulletin:not(.emphasis-high) .bulletin-circle:after,
  .compilation .stream-item:first-child .bulletin:not(.emphasis-high) .bulletin-circle:after,
  .stream-item:first-child .no-compilations:not(.emphasis-high) .bulletin-circle:after {
    background: $darkmode-body-background
  }

}
// --- DARKMODE END ---

.bulletin {
	position:relative;
	margin-bottom:0;
	//padding:0 0 30px 15px;
	padding:0 0 24px 12px;
}

.bulletin.emphasis-high {
	padding:15px 12px;
}

.surrogate > .teaser-reference > .bulletin {
	border-left: 1px solid #E0E0E0;
	margin:30px 10px;
}

.surrogate > .teaser-reference > .bulletin:not(.emphasis-high) {
	padding-bottom: 0;
}

.bulletin-wrapper {
	overflow: hidden;
}

.bulletin-compilations {
  margin-top: 0.275rem;
	margin-bottom: 8px;
}

.bulletin-compilations a {
	display: inline-block;
	margin-right: 0.75em;

}

.bulletin-context {
	margin-top: 12px;
	> aside {
		margin-top: 4px;
	}
}

.compilation.reference {
  &:not(&:nth-last-child(1)) {
    &:after {
      content: ',';
      display: inline-block;
      width: 0;
    }
  }
}

.bulletin-circle {
	display: block;
	content: " ";
	position: absolute;
	top: 1px;
	left: -11px;
	width: 14px;
	height: 14px;
	border-radius: 100%;
	border-width: 4px;
	border-style: solid;
	background: #fff;

  &.skin-border-600 {
    background: #1767ce;
    border-color: #FFFFFF !important;
  }
}

.compilation .bulletin-circle,
.no-compilations .bulletin-circle {
	top:1px;
}


@media screen and (max-width: 719px) {
	.newsroom-seen .bulletin-circle {
		animation: circle-anim 0.85s cubic-bezier(0.455, 0.030, 0.515, 0.955);
		animation-fill-mode: forwards;
	}

	.newsroom-seen-unresolved .bulletin-circle {
		animation-duration:0;
	}
}

@media screen and (min-width: 720px) {
	.surrogate > .teaser-reference > .bulletin {
		width:66.66%;
		margin:60px auto;
	}

	.bulletin {
		padding:0 0 30px 15px;
	}

	.bulletin.emphasis-high {
		padding:15px;
	}
	.bulletin-context {
		max-width: 70%;
	}

}



@keyframes circle-anim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
    border-color:currentColor;
  }
  100% {
    transform: scale(1);
  }
}

// Bulletin sharing
.bulletin-share { display:none; padding: 2px 3px }
.bulletin-share a { width: 38px }
.surrogate > .teaser-reference > .bulletin .bulletin-share {
	display: block;
	margin-top: 20px;
}

.bulletin-text {
  line-height: 1.35rem;
  padding-bottom: 1px;
}

.bulletin-text-body {
  display: inline;
}

.bulletin-text > .bulletin-text-body {
	font-size: 1rem;
  line-height: 1.45;
}

.bulletin-text > .bulletin-text-body p {
  opacity: 0.8;
}

.bulletin-text > p,
.bulletin-text > .bulletin-text-body {
	position: relative;
	margin: 0 0 15px 0;
}

.bulletin-text .bulletin-text-body > p:first-child,
.bulletin-title + p {
	margin-top: 0;
	display: inline;
  padding-bottom: 3px; // Link-underline is not visible on the last line unless we add some padding on Safari
}

/* p:first-child is inline so won't get margin-bottom */
.bulletin-text > p:nth-of-type(2) {
	margin-top: 15px;
}

.bulletin-text > p:last-child, .bulletin-text > .bulletin-text-body > p:last-child {
	margin-bottom: 0;
}

.bulletin-time {
	font-size: 15px;
	display: inline;
	margin-right:4px;
}

.bulletin-text > .bulletin-time > a,
.bulletin-text > .bulletin-time > a:link,
.bulletin-text > .bulletin-time > a:visited {
  border-bottom: none;
  font-weight: 600;
  display: inline;
}

.bulletin-text > .bulletin-title {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.33rem;
  display: block;
  opacity: 1;
}

.bulletin .plug-svg-arrow {
	position:absolute;
	right:15px;
	top:50%;
	margin-top:-11px;
	width:9px;
	height:23px;
  color:#26292A;
}

.bulletin a:hover .plug-svg-arrow {
	color: #00B9F2;
}

/* Enforce oembedded Instagram iframe to have responsive/ fluid to fit the mobile screen. */
[id^="instagram-embed-"] {
	min-width: auto !important;
}

/* bulletin attachment (image, video, tweet etc.) */
.bulletin-attachment {
	margin-bottom: 0;
	margin-top: 12px;
	clear: both;
}

/* embedded article plug */
.bulletin-attachment > a {
	display: block;
	position: relative;
}

.bulletin-attachment.plug {
	border: 1px solid #E0E0E1;
	margin-bottom: 0 !important;
	min-height: 84px;
  background: #FFFFFF;
}
.bulletin-attachment.plug .plug-image {
  margin-bottom: 0px;
}
.bulletin-attachment.plug .title,
.bulletin-attachment.video .title {
	fontSize(16px, 18px);
	color:#26292A;
}

.bulletin-attachment.plug .plug-preamble,
.bulletin-attachment.plug.article .plug-metadata-footer {
	display: none;
}

.bulletin-attachment.plug .plug-teaser {
	padding: 10px 30px 5px 15px;
}

.bulletin-attachment.plug .plug-image + .plug-teaser {
	padding: 15px 30px 10px 15px;
}

.bulletin .bulletin-attachment .plug-teaser {
	position: relative;
}

.bulletin-attachment.plug .plug-teaser:before {
	display: block;
	content: 'ARTIKKEL';
	font-size: 12px;
	font-weight: normal;
	color: rgba(38, 41, 42, 0.8);
	margin-bottom: 5px;
	letter-spacing: 0.05em;
}

.bulletin-attachment.plug .plug-svg-arrow {
	display: block;
}

.bulletin-attachment.plug-feature .title {
	font-family: FONT-FAMILY-NRK-SANS;
	font-weight: 800;
	letter-spacing: 0.001em
}

/* emphasis-high with inverted colors */
.bulletin.emphasis-high {
	background-color: #061629;
}

.bulletin.emphasis-high .bulletin-circle {
	display: none !important;
}

.bulletin.emphasis-high figcaption,
.bulletin.emphasis-high .bulletin-header .skin-text,
.bulletin.emphasis-high .bulletin-share a,
.bulletin.emphasis-high .compilation-link a,
.bulletin.emphasis-high .bulletin-text a,
.bulletin.emphasis-high .bulletin-text > p,
.bulletin.emphasis-high .bulletin-text > .bulletin-text-body > p,
.bulletin.emphasis-high .bulletin-text > .bulletin-text-body {
	color: #fff;
  opacity: 1;
}

.bulletin.emphasis-high .bulletin-title {
  color: #FF7461;
  opacity: 1;
}

.bulletin.emphasis-high .compilation-link a:hover,
.bulletin.emphasis-high .bulletin-text p a {
	border-bottom: 1px solid #fff;
}

.bulletin.emphasis-high .bulletin-time a:link,
.bulletin.emphasis-high .bulletin-time a:visited {
	border-color: #fff;
  color: #fff;
}

.bulletin.emphasis-high .skin-background {
	background-color: #fff !important;
}

.bulletin.emphasis-high .bulletin-attachment.plug {
	background: #fff;
	border-color: #fff;
}

// hide border above first
.stream-item:first-child .bulletin:not(.emphasis-high) .bulletin-circle:after {
	display: block;
	content: " ";
	position: absolute;
	top: -6px;
	left: 1px;
	width: 1px;
	height: 2px;
	background: #fff;
}

.surrogate > .teaser-reference > .bulletin:not(.emphasis-high) .bulletin-circle:after,
.compilation .stream-item:first-child .bulletin:not(.emphasis-high) .bulletin-circle:after,
.stream-item:first-child .no-compilations:not(.emphasis-high) .bulletin-circle:after {
	display: block;
	content: " ";
	position: absolute;
	top: -10px;
	left: 1px;
	width: 1px;
	height: 6px;
	background: #fff;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {

    .bulletin-attachment.plug > a:after {
    	background-size: 10px auto;
    	background-position: 0 -60px;
    }
}

@media screen and (min-width: 1050px) {

	.sg9 > div > .stream-content .plug-image {
		width: 50%;
		float: left;
		margin-bottom: 0;
	}

	.sg9 > div > .stream-content .plug-teaser {
		padding: 16px 32px 0 23px;
	}

	.sg9 > div > .stream-content .plug-image ~ .plug-teaser {
		padding: 16px 32px 16px 23px;
		margin-left: 50%;
	}

	.sg9 > div > .stream-content .plug-image ~ .plug-teaser:before {
		margin-bottom:14px;
	}

	.sg9 > div > .stream-content .plug-footer {
		margin-top:20px;
	}

	.bulletin .bulletin-attachment.plug-feature .plug-teaser {
		position: relative;
	}

	.bulletin .bulletin-attachment .plug-teaser {
		position: relative;
	}
}

@media screen and (min-width: 720px) {

	.bulletin .bulletin-attachment.plug .title,
	.bulletin .bulletin-attachment.video .title {
		fontSize(22px, 25px);
	}

}
