.article.brief .atlas figcaption ,
.article.lean .atlas figcaption {
  display: none;
}

.atlas__canvas {
  padding-bottom: 100%;
  height: auto;
  position: relative;
}
.F169 > .atlas__canvas { padding-bottom: 56.25% }
.F163 > .atlas__canvas { padding-bottom: 18.75% }
.F43 > .atlas__canvas { padding-bottom: 75% }
.F34 > .atlas__canvas { padding-bottom: 133.333% }

.article-lead-media .atlas__canvas {
  padding-bottom: 56.25%;

  @media screen and (min-width: 720px) {
    padding-bottom: 56.25%;
  }
}

.compilation-media .atlas {
  max-width: 1800px;
  margin: 0 auto;
}
.compilation-media .atlas__canvas {
  padding-bottom: 40%;
}

.atlas__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
}
