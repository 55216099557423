/*
body > .page {
	padding-top: 1px;
	padding-bottom: 30px;
}
*/

.grid.site {
	background-color: #fff;
	position: relative;
}

.site {
	margin: 20px auto 20px auto;
	border: 1px solid #b9b9b9;
	border-radius: 3px;
}

/*
.site .notice {
	background-color: #c8ddf2;
	text-decoration: none;
	height: 0;
	display: block;
	overflow: hidden;
	padding: 0;
	transition: all 1s;
	-moz-transition: all 1s;
	-ms-transition: all 1s;
	-o-transition: all 1s;
	-webkit-transition: all 1s;
	transform: 1s;
	-moz-transform: 1s;
	-webkit-transform: 1s;
	-ms-transform: 1s;
	-o-transform: 1s;
}

.site .notice.visible {
	border: 1px solid #0159b2;
	height: auto;
	padding: 1em;
}

*/