@require '../../common/css/mixins';

$quote-font-size = 1.5rem;
$quote-circle-size = $quote-font-size;
$quote-line-width = 1px;
$quote-circle-spacing = 5px;
$quote-text-padding = 1rem;

.quote blockquote {
  margin: 0;
  padding-left: 0;
  font-size: $quote-font-size;
}

.quote-text {
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border-left-style: solid;
  border-left-width: $quote-line-width;
  padding: 0 $quote-text-padding 0;
  position: relative;
  line-height: 1;
  margin: 0;
  margin-bottom: 0.1em;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  word-wrap: break-word;
  margin-left: "calc(%s + %s)" % ($quote-circle-size $quote-circle-spacing);
}

  .quote-decoration {
    display: block;
    position: absolute;
    width: $quote-circle-size;
    height: $quote-circle-size;
    border-radius: 50%;
  }

.quote-text p {
  line-height: 1.25;
  font-weight: 300;
  margin-bottom: 0.6em;
}

.quote-source {
  display: block;
  line-height: 1.4;
  font-weight: 400;
  font-size: 16px;
  margin-left: "calc(%s + %s + %s + %s)" % ($quote-circle-size $quote-line-width $quote-circle-spacing $quote-text-padding);
}

.quote-source.quote-personname, .quote-source.quote-source-oneline {
  font-weight: 740;
}

.quote-source.quote-cite {
  font-style: italic;
}
@media screen and (min-width: 600px) {
  .quote blockquote {
    padding: 10px 0;
    margin: 0;
  }
}
