.highcharts {
  [data-highcharts-chart] ~ .skin-loader {
    display: none;
  }
}

.highchartfeil {
  color: #D8000C;
  background-color: #FFD2D2;
  border: 1px solid;
  padding-left: 3px;
}