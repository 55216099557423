@require './mixins';

// --- DARKMODE ---
html.darkmode {
	.alert {
		background-color: $darkmode-body-background;
		border-color: $darkmode-border;
		&:before {
			{$darkmode-color-overlay-filter}  
		}
	}
}
// --- DARKMODE END --- 

.alert {
	display: block;
	margin: 20px 0;
	padding: 18px;
	font-size: 14px;
	background: #f6f6f6;
	border: 1px solid #e0e0e0;

	@media $mq-small {
		padding: 10px;
		font-size: 13px;
	}

	&:before {
		content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178 178"><path d="M94.3 67.8v-27H82.7v27l3 38h5.6l3-38 M81.7 114.8h13.6v13.6H81.7z M89 0a89 89 0 1 0 89 89A89 89 0 0 0 89 0zm0 172a83 83 0 1 1 83-83 83 83 0 0 1-83 83z"/></svg>');
		float: left;
		width: 24px;
		height: 24px;
		margin: 0 7px 0 0;
	}
}

.alert--large {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	height: 100%;

	&:before {
		margin: 0 0 10px 0;
	}
}

.alert--success{
	background: #a1d5d0;
}

.alert--error{
	background: #eeb2c3;
}

.alert--info{
	background: #b1deed;
}

.alert--once + .alert--once{
	display: none;
}
